import { ApolloLink, createHttpLink, from } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { API_URL } from 'app_constants';
import { Admin, PermissionRoles } from 'domain/entities/Admin';
import { Expert } from 'domain/entities/Expert';
import type { UserRole } from 'domain/entities/IUser';
import { PrimitiveAtom, Setter, atom, createStore } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import jwt from 'jsonwebtoken';
import apolloClient from 'utils/apollo';

type DecodedToken = {
  userId: string;
  role: UserRole;
  permissionRole: PermissionRoles;
  exp: number;
  iat: number;
};

interface IAuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  isUserLoaded: boolean;
  role: UserRole | null;
  userId: string | null;
  user: Expert | Admin | null;
  permissionRole: PermissionRoles | null;
}

let authenticate;

export const authStore = createStore();

const setSession = (accessToken: string | null): void => {
  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }) => ({
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : undefined,
        'Apollo-Require-Preflight': 'true',
      },
    }));
    return forward(operation);
  });
  const uploadLink = createUploadLink({ uri: `${API_URL}/graphql` });
  const httpLink = createHttpLink({ uri: `${API_URL}/graphql` });
  // Suggested in documentation, because of uploadLink not matching ApolloLink type
  // @ts-ignore
  apolloClient.setLink(from([authLink, uploadLink, httpLink]));
};

export const accessTokenAtom = atomWithStorage('accessToken', '', window.localStorage, {
  getOnInit: true,
});

export const userIdAtom = atom(null) as PrimitiveAtom<string | null>;

export const roleAtom = atom(null) as PrimitiveAtom<UserRole | null>;

export const userAtom = atom(null) as PrimitiveAtom<Expert | Admin | null>;

export const permissionRoleAtom = atom(null) as PrimitiveAtom<PermissionRoles | null>;

type AuthStateAction =
  | {
      type: 'LOGOUT' | 'AUTHENTICATE';
    }
  | { type: 'REFRESH_TOKEN'; token: string; removeUser?: boolean };

export const authStateAtom = atom(
  (get) => {
    const role = get(roleAtom);
    const userId = get(userIdAtom);
    const user = get(userAtom);
    const permissionRole = get(permissionRoleAtom);

    return {
      isAuthenticated: !!role,
      isInitialised: true,
      isUserLoaded: !!user,
      role,
      user,
      userId,
      permissionRole,
    } as IAuthState;
  },
  (get, set: Setter, action: AuthStateAction) => {
    if (action.type === 'LOGOUT') {
      set(accessTokenAtom, '');
      set(userIdAtom, null);
      set(roleAtom, null);
      set(userAtom, null);
      set(permissionRoleAtom, null);
      setSession(null);
      apolloClient.clearStore().catch((e) => console.error(e));
    } else if (action.type === 'AUTHENTICATE') {
      authenticate(get, set);
    } else if (action.type === 'REFRESH_TOKEN') {
      set(accessTokenAtom, action.token);
      if (action.removeUser) {
        set(userAtom, null);
      }
      authenticate(get, set, action.token);
    }
  },
);

export const lastRefreshedAtom = atom(60 * 15 - 1);

authenticate = function (get, set, token?: string) {
  const accessToken = token ?? get(accessTokenAtom);
  if (accessToken) {
    const decoded = jwt.decode(accessToken) as DecodedToken;
    if (decoded.exp * 1000 < Date.now()) {
      set(accessTokenAtom, '');
      set(userIdAtom, null);
      set(roleAtom, null);
      set(userAtom, null);
      set(permissionRoleAtom, null);
      setSession(null);
      apolloClient.clearStore().catch((e) => console.error(e));
      return;
    }
    set(userIdAtom, decoded.userId);
    set(roleAtom, decoded.role);
    set(permissionRoleAtom, decoded.role === 'expert' ? 'expert' : decoded.permissionRole);

    setSession(accessToken);
  } else {
    set(userIdAtom, null);
    set(roleAtom, null);
    set(permissionRoleAtom, null);
  }
};

authStore.set(authStateAtom, { type: 'AUTHENTICATE' });
