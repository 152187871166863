import { gql, useQuery } from '@apollo/client';
import { parseISO } from 'date-fns';
import IExpertAdapter, {
  ExpertAvailability,
  UpdateRequestValue,
} from 'domain/adapters/pageDataAdapters/IExpertAdapter';
import { WithEmptyFetchMore, WithFetchMore } from 'domain/adapters/pageDataAdapters/types';
import { Expert, ExpertRequestUpdateValues, ExpertSort } from 'domain/entities/Expert';
import { ISODate } from 'domain/types/IAvailability';
import { PaginatedResult, Pagination } from 'domain/types/Pagination';
import { QueryResult } from 'domain/types/QueryResult';
import { Sort, SortOrder } from 'domain/types/Sort';
import TimeRange from 'domain/types/TimeRange';
import { ID } from 'domain/types/common';
import useQueryMemo from 'infrastructure/adapters/graphQLAdapters/useQueryMemo';
import mapTranslatableStr from 'infrastructure/common/mapTranslatableStr';
import { useMemo } from 'react';
import client from 'utils/apollo';

const GQL_ACTIONS = {
  REQUEST_UPDATE: gql`
    mutation RequestUpdate($id: ID!, $input: ExpertUpdateData!) {
      requestUpdateExpert(expertId: $id, expert: $input)
    }
  `,
  GET_ALL_EXPERTS: gql`
    query GetAllExperts($nameOrCustomIdFilter: String, $sort: Sort, $pagination: Pagination!, $areaFilter: AreaFilter) {
      experts(nameOrCustomId: $nameOrCustomIdFilter, sort: $sort, pagination: $pagination, areaFilter: $areaFilter) {
        nodes {
          id
          customId
          email
          name
          profilePictureUrl
          rank
          postalCodes
          description {
            lang
            value
          }
          languages {
            lang
            value
          }
          expertType
        }
        count
      }
    }
  `,
  GET_SEARCHED_EXPERTS: gql`
    query GetSearchedExperts($nameOrCustomId: String, $sort: Sort!, $pagination: Pagination!) {
      experts(nameOrCustomId: $nameOrCustomId, sort: $sort, pagination: $pagination) {
        nodes {
          id
          name
          profilePictureUrl
          treatmentTypes
        }
        count
      }
    }
  `,
  GET_EXPERT_DETAILS: gql`
    query GetExpertById($id: ID!) {
      expert(expertId: $id) {
        id
        customId
        name
        birthday
        phoneNumber
        slogan {
          lang
          value
        }
        blocked
        description {
          lang
          value
        }
        email
        experience {
          lang
          value
        }
        languages {
          lang
          value
        }
        media {
          type
          uri
        }
        treatmentTypes
        postalCodes
        profilePictureUrl
        rank
        verified
        iban
        payoutActive
        payoutEnabled
        experienceLevel
        expertType
      }
    }
  `,
  GET_ALL_UPDATE_REQUESTS: gql`
    query GetAllUpdateRequests($nameFilter: String, $sort: Sort, $pagination: Pagination!) {
      updateRequests(expertName: $nameFilter, pagination: $pagination, sort: $sort) {
        count
        nodes {
          id
          email
          name
          profilePictureUrl
          birthday
          customId
        }
      }
    }
  `,
  GET_UPDATE_REQUEST_COUNT: gql`
    query GetUpdateRequestCount($pagination: Pagination!) {
      updateRequests(pagination: $pagination) {
        count
      }
    }
  `,
  GET_UPDATE_REQUEST: gql`
    query GetUpdateRequestById($id: ID!) {
      updateRequest(expertId: $id) {
        expert {
          id
          name
          experienceLevel
          birthday
          phoneNumber
          slogan {
            lang
            value
          }
          blocked
          description {
            lang
            value
          }
          email
          experience {
            lang
            value
          }
          languages {
            lang
            value
          }
          media {
            type
            uri
          }
          customId
          treatmentTypes
          postalCodes
          profilePictureUrl
          iban
          verified
          birthday
        }
        updateRequestData {
          id
          name
          birthday
          experienceLevel
          phoneNumber
          slogan {
            lang
            value
          }
          blocked
          description {
            lang
            value
          }
          email
          experience {
            lang
            value
          }
          languages {
            lang
            value
          }
          media {
            type
            uri
          }
          customId
          treatmentTypes
          postalCodes
          profilePictureUrl
          iban
          verified
        }
      }
    }
  `,
  GET_EXPERTS_WITH_AVAILABILITY: gql`
    query GetExpertsWithAvailability(
      $postalCode: String!
      $pagination: Pagination!
      $rangeStart: Date!
      $rangeEnd: Date!
      $treatmentType: ID!
      $treatmentLength: Int!
    ) {
      expertAvailabilityInfo(
        pagination: $pagination
        postalCode: $postalCode
        rangeStart: $rangeStart
        rangeEnd: $rangeEnd
        treatmentLength: $treatmentLength
        treatmentTypeId: $treatmentType
      ) {
        name
        id
        profilePictureUrl
        availabilities
      }
    }
  `,
};

const ExpertGQLAdapter: IExpertAdapter = {
  async requestUpdate(id: string, values: Partial<ExpertRequestUpdateValues>): Promise<any> {
    const response = await client.mutate({
      mutation: GQL_ACTIONS.REQUEST_UPDATE,
      variables: {
        id,
        input: values,
      },
      refetchQueries: ['GetExpertById'],
    });
    if (!response.data) {
      throw new Error('Error while updating');
    }
    return response.data;
  },

  useUpdateRequestCount(): QueryResult<{ count: number }> {
    const query = useQuery(GQL_ACTIONS.GET_UPDATE_REQUEST_COUNT, {
      variables: {
        pagination: {
          offset: 0,
          limit: 1,
        } as Pagination,
      },
      pollInterval: 60000,
      fetchPolicy: 'network-only',
    });

    return useQueryMemo<{ count: number }>(
      query,
      query.data?.updateRequests && { count: query.data.updateRequests.count },
    );
  },

  useExperts(
    nameOrCustomId: string | null,
    pagination: Pagination,
    sort: Sort<ExpertSort> | null,
    areaFilter: ID | null,
  ): QueryResult<PaginatedResult<{ experts: Expert[] }>> {
    const query = useQuery<{ experts: PaginatedResult<{ nodes: Expert[] }> }>(
      GQL_ACTIONS.GET_ALL_EXPERTS,
      {
        variables: {
          nameOrCustomIdFilter: nameOrCustomId?.length >= 2 ? nameOrCustomId : null,
          pagination,
          ...(sort
            ? {
                sort: {
                  field: sort.field,
                  order: sort.order,
                },
              }
            : {}),
          areaFilter: areaFilter ? { areaOrLocationId: areaFilter } : undefined,
        },
      },
    );
    return useMemo(
      () => ({
        refetch: query.refetch,
        loading: query.loading,
        error: !!query.error,
        ...(query.data?.experts
          ? {
              data: {
                count: query.data.experts?.count || 0,
                experts: query.data.experts?.nodes || [],
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },

  useExpertDetails(id: ID): QueryResult<{ expert: Expert }> {
    const query = useQuery(GQL_ACTIONS.GET_EXPERT_DETAILS, {
      variables: {
        id,
      },
    });
    return useMemo(
      () => ({
        refetch: query.refetch,
        loading: query.loading,
        error: !!query.error,
        ...(query.data?.expert
          ? {
              data: {
                expert: {
                  ...query.data.expert,
                  slogan: mapTranslatableStr(query.data.expert.slogan),
                  languages: mapTranslatableStr(query.data.expert.languages),
                  description: mapTranslatableStr(query.data.expert.description),
                  experience: mapTranslatableStr(query.data.expert.experience),
                },
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },

  useUpdateRequests(
    name: string | null,
    pagination: Pagination,
    sort: Sort<ExpertSort> | null,
  ): QueryResult<PaginatedResult<{ updateRequests: Expert[] }>> {
    const query = useQuery<{ updateRequests: PaginatedResult<{ nodes: Expert[] }> }>(
      GQL_ACTIONS.GET_ALL_UPDATE_REQUESTS,
      {
        variables: {
          nameFilter: name?.length >= 2 ? name : null,
          pagination,
          ...(sort
            ? {
                sort: {
                  field: sort.field,
                  order: sort.order,
                },
              }
            : {}),
        },
      },
    );
    return useMemo(
      () => ({
        refetch: query.refetch,
        loading: query.loading,
        error: !!query.error,
        ...(query.data?.updateRequests
          ? {
              data: {
                count: query.data.updateRequests?.count || 0,
                updateRequests: query.data.updateRequests?.nodes || [],
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },

  useUpdateRequestDetails(id: ID): QueryResult<{ updateRequest: UpdateRequestValue }> {
    const query = useQuery(GQL_ACTIONS.GET_UPDATE_REQUEST, {
      variables: {
        id,
      },
    });
    return useMemo(() => {
      const updateRequest = query.data?.updateRequest?.updateRequestData;
      const expert = query.data?.updateRequest?.expert;

      return {
        refetch: query.refetch,
        loading: query.loading,
        error: !!query.error,
        ...(updateRequest && expert
          ? {
              data: {
                updateRequest: {
                  id: {
                    oldValue: expert.id,
                    newValue: updateRequest.id,
                  },
                  birthday: {
                    oldValue: expert.birthday,
                    newValue: updateRequest.birthday,
                  },
                  name: {
                    oldValue: expert.name,
                    newValue: updateRequest.name,
                  },
                  customId: {
                    oldValue: expert.customId,
                    newValue: updateRequest.customId,
                  },
                  email: {
                    oldValue: expert.email,
                    newValue: updateRequest.email,
                  },
                  phoneNumber: {
                    oldValue: expert.phoneNumber,
                    newValue: updateRequest.phoneNumber,
                  },
                  slogan: {
                    oldValue: mapTranslatableStr(expert.slogan),
                    newValue: mapTranslatableStr(updateRequest.slogan),
                  },
                  blocked: {
                    oldValue: expert.blocked,
                    newValue: updateRequest.blocked,
                  },
                  description: {
                    oldValue: mapTranslatableStr(expert.description),
                    newValue: mapTranslatableStr(updateRequest.description),
                  },
                  experience: {
                    oldValue: mapTranslatableStr(expert.experience),
                    newValue: mapTranslatableStr(updateRequest.experience),
                  },
                  languages: {
                    oldValue: mapTranslatableStr(expert.languages),
                    newValue: mapTranslatableStr(updateRequest.languages),
                  },
                  media: {
                    oldValue: expert.media,
                    newValue: updateRequest.media,
                  },
                  treatmentTypes: {
                    oldValue: expert.treatmentTypes,
                    newValue: updateRequest.treatmentTypes,
                  },
                  postalCodes: {
                    oldValue: expert.postalCodes,
                    newValue: updateRequest.postalCodes,
                  },
                  profilePictureUrl: {
                    oldValue: expert.profilePictureUrl,
                    newValue: updateRequest.profilePictureUrl,
                  },
                  iban: {
                    oldValue: expert.iban,
                    newValue: updateRequest.iban,
                  },
                },
              },
            }
          : {}),
      };
    }, [query.data, query.error, query.loading, query.refetch]);
  },
  useSearchedExperts(
    nameOrCustomId: string | null,
    limit: number,
  ): WithFetchMore<QueryResult<{ experts: Expert[]; count: number }>, { name: string }> {
    const query = useQuery<{ experts: PaginatedResult<{ nodes: Expert[] }> }>(
      GQL_ACTIONS.GET_SEARCHED_EXPERTS,
      {
        variables: {
          nameOrCustomId,
          sort: {
            field: 'name',
            order: SortOrder.Asc,
          } as Sort<ExpertSort>,
          pagination: {
            offset: 0,
            limit,
          },
        },
      },
    );

    return useMemo(
      () => ({
        refetch: query.refetch,
        loading: query.loading,
        error: !!query.error,
        fetchMore: (variables) =>
          query.fetchMore({
            variables,
            updateQuery(previousResult, { fetchMoreResult }) {
              const previousExperts = previousResult.experts.nodes;
              const newExperts = fetchMoreResult.experts.nodes;
              return {
                experts: {
                  nodes: [...previousExperts, ...newExperts],
                  count: fetchMoreResult.experts.count,
                },
              };
            },
          }),
        ...(query.data?.experts?.nodes
          ? {
              data: {
                experts: query.data.experts.nodes,
                count: query.data.experts.count,
              },
            }
          : {}),
      }),
      [query],
    );
  },
  useExpertsWithAvailabilities(
    input: {
      treatmentLength: number;
      treatmentTypeId: ID;
      postalCode: string;
      timeRange: TimeRange;
    },
    pagination: Pagination,
  ): WithEmptyFetchMore<
    QueryResult<{
      experts: ExpertAvailability[];
    }>
  > {
    const { refetch, loading, error, data, fetchMore } = useQuery<{
      expertAvailabilityInfo: (Omit<ExpertAvailability, 'availabilities'> & {
        availabilities: ISODate[];
      })[];
    }>(GQL_ACTIONS.GET_EXPERTS_WITH_AVAILABILITY, {
      variables: {
        postalCode: input.postalCode,
        treatmentType: input.treatmentTypeId,
        treatmentLength: input.treatmentLength,
        rangeStart: input.timeRange.fromDate,
        rangeEnd: input.timeRange.toDate,
        pagination,
      },
    });
    return useMemo(
      () => ({
        refetch: refetch,
        loading: loading,
        error: !!error,
        fetchMore: () =>
          fetchMore({
            variables: {
              ...input,
              pagination: {
                ...pagination,
                offset: data?.expertAvailabilityInfo?.length || 0,
              },
            },
            updateQuery(previousResult, { fetchMoreResult }) {
              const previousExperts = previousResult.expertAvailabilityInfo;
              const newExperts = fetchMoreResult.expertAvailabilityInfo;
              return {
                expertAvailabilityInfo: [...previousExperts, ...newExperts],
              };
            },
          }),
        ...(data?.expertAvailabilityInfo
          ? {
              data: {
                experts: data.expertAvailabilityInfo.map((e) => ({
                  ...e,
                  availabilities: e.availabilities.map((d) => parseISO(d)),
                })),
              },
            }
          : {}),
      }),
      [data, error, fetchMore, input, loading, pagination, refetch],
    );
  },
};

export default ExpertGQLAdapter;
