import { IUser } from 'domain/entities/IUser';

export interface Admin extends IUser {
  role: 'admin';
  permissionRole: AdminPermissionRole;
  blocked: boolean;
}

export enum AdminPermissionRole {
  SuperAdmin = 'superAdmin',
  Manager = 'manager',
  Associate = 'associate',
  Sales = 'sales',
}

export type PermissionRoles = AdminPermissionRole | 'expert';

export type AdminInput = Omit<Admin, 'role' | 'id'>;
