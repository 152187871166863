import { THEMES } from 'app_constants';
import i18n from 'i18next';
import _ from 'lodash';
import React, { createContext, useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';

interface ISettings {
  direction?: 'ltr' | 'rtl';
  responsiveFontSizes?: boolean;
  theme?: string;
  language?: string;
}

export interface ISettingsContextValue {
  settings: ISettings;
  saveSettings: (update: ISettings) => void;
}

interface ISettingsProviderProps {
  settings?: ISettings;
  children?: ReactNode;
}

const defaultSettings: ISettings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  theme: THEMES.LIGHT,
  language: i18n.language,
};

export const restoreSettings = (): ISettings | null => {
  let settings = null;

  try {
    const storedData: string | null = window.localStorage.getItem('settings');

    if (storedData) {
      settings = JSON.parse(storedData);
    }
  } catch (_err) {
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return settings;
};

export const storeSettings = (settings: ISettings): void => {
  window.localStorage.setItem('settings', JSON.stringify(settings));
};

const SettingsContext = createContext<ISettingsContextValue>({
  settings: defaultSettings,
  saveSettings: () => {},
});

export const SettingsProvider: FC<ISettingsProviderProps> = ({ settings, children }) => {
  const [currentSettings, setCurrentSettings] = useState<ISettings>(settings || defaultSettings);

  const handleSaveSettings = (update: ISettings = {}): void => {
    const mergedSettings = _.merge({}, currentSettings, update);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  useEffect(() => {
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      setCurrentSettings(restoredSettings);
    }
  }, []);

  useEffect(() => {
    document.dir = currentSettings.direction;
  }, [currentSettings]);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
