import { AppBar, Box, Button, Hidden, Toolbar, Typography, makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import { APP_VERSION } from 'app_constants';
import clsx from 'clsx';
import Logo from 'components/Logo';
import PropTypes from 'prop-types';
import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useContextTranslation } from 'translation/useContextTranslation';

interface ITopBarProps {
  className?: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: { backgroundColor: theme.palette.background.default },
    toolbar: { height: 80 },
    logo: { marginRight: theme.spacing(2), height: 60 },
    link: {
      fontWeight: theme.typography.fontWeightMedium as number,
      '& + &': { marginLeft: theme.spacing(2) },
    },
    divider: {
      width: 1,
      height: 32,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  }),
);

const TopBar: FC<ITopBarProps> = ({ className, ...rest }) => {
  const classes = useStyles();
  const t = useContextTranslation('layout.topbar');

  return (
    <AppBar className={clsx(classes.root, className)} color="secondary" {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo className={classes.logo} />
        </RouterLink>
        <Hidden mdDown>
          <Typography variant="caption" color="textSecondary">
            Version {APP_VERSION}
          </Typography>
        </Hidden>
        <Box flexGrow={1} />
        <Button color="primary" component={RouterLink} to="/login" variant="contained" size="small">
          {t('sign_in')}
        </Button>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = { className: PropTypes.string };

export default TopBar;
