/* eslint-disable no-use-before-define,@typescript-eslint/no-use-before-define */
import type { FC, ReactNode } from 'react';
import React, { useEffect } from 'react';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import styles from './NavBar.module.scss';

import { Box, Divider, Drawer, Hidden, List, ListSubheader } from '@material-ui/core';
import { HotelRounded, HouseRounded, CardGiftcard as VoucherIcon } from '@material-ui/icons';
import Logo from 'components/Logo';
import { AdminPermissionRole, PermissionRoles } from 'domain/entities/Admin';
import { UserRole } from 'domain/entities/IUser';
import injectables from 'domain/injectables';
import INavNotificationService from 'domain/services/INavNotificationService';
import useAuth from 'hooks/useAuth';
import { TFunction } from 'i18next';
import { useInject } from 'inversify-hooks';
import {
  Anchor,
  Award,
  BarChart,
  BookOpen as BookingsIcon,
  Calendar as CalendarIcon,
  Coffee,
  DollarSign as DiscountsIcon,
  DollarSign,
  ShoppingCart as ExpertsIcon,
  List as ListIcon,
  Percent,
  FileText as ReviewsIcon,
  TrendingUp,
  UserCheck as UpdateRequestsIcon,
  User as UserIcon,
  Users as UsersIcon,
  Gift as VoucherDiscountsIcon,
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useContextTranslation } from 'translation/useContextTranslation';
import NavItem from './NavItem';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  role?: UserRole;
  items?: Item[];
  title: string;
}

interface Section {
  items: Item[];
  role?: PermissionRoles | PermissionRoles[];
  subheader: string;
}

const managerSection: (t: TFunction) => Item[] = (t) => [
  {
    title: t('admin.calendar'),
    href: '/app/admin/calendar',
    icon: CalendarIcon,
  },
  {
    title: t('admin.treatment_types'),
    href: '/app/admin/treatmentTypes',
    icon: ListIcon,
  },
  {
    title: t('admin.clients'),
    href: '/app/admin/clients',
    icon: UsersIcon,
  },
  {
    title: t('admin.experts'),
    href: '/app/admin/experts',
    icon: ExpertsIcon,
  },
  {
    title: t('admin.payouts'),
    href: '/app/admin/payouts',
    icon: TrendingUp,
  },
  {
    title: t('admin.update_requests'),
    href: '/app/admin/update_requests',
    icon: UpdateRequestsIcon,
  },
  {
    title: t('admin.booking_list'),
    href: '/app/admin/bookings',
    icon: BookingsIcon,
  },
  {
    title: t('admin.discount_list'),
    href: '/app/admin/discounts',
    icon: DiscountsIcon,
  },
  {
    title: t('admin.voucher_discount_list'),
    href: '/app/admin/voucher_discounts',
    icon: VoucherDiscountsIcon,
  },
  {
    title: t('admin.voucher_list'),
    href: '/app/admin/vouchers',
    icon: VoucherIcon,
  },
  {
    title: t('admin.banner_list'),
    href: '/app/admin/banners',
    icon: Percent,
  },
  {
    title: t('admin.stats'),
    href: '/app/admin/stats',
    icon: BarChart,
  },
  {
    title: t('admin.locations'),
    href: '/app/admin/locations',
    icon: HotelRounded,
  },
  {
    title: t('admin.hotel_request'),
    href: '/app/admin/hotelRequest',
    icon: HouseRounded,
  },
];

const sections: (t: TFunction) => Section[] = (t) => [
  {
    subheader: t('administrative'),
    role: [AdminPermissionRole.SuperAdmin],
    items: [
      ...managerSection(t),
      {
        title: t('admin.list_admin_user'),
        href: '/app/admin/adminUsers',
        icon: Anchor,
      },
      {
        title: t('admin.treatment_selections'),
        href: '/app/admin/treatmentSelections',
        icon: Coffee,
      },
      {
        title: t('admin.promo_code_selections'),
        href: '/app/admin/promoCodeSelections',
        icon: Award,
      },
    ],
  },
  {
    subheader: t('administrative'),
    role: [AdminPermissionRole.Manager],
    items: managerSection(t),
  },
  {
    subheader: t('administrative'),
    role: [AdminPermissionRole.Associate],
    items: managerSection(t),
  },
  {
    subheader: t('administrative'),
    role: [AdminPermissionRole.Sales],
    items: [
      {
        title: t('admin.treatment_types'),
        href: '/app/admin/treatmentTypes',
        icon: ListIcon,
      },
      {
        title: t('admin.clients'),
        href: '/app/admin/clients',
        icon: UsersIcon,
      },
      {
        title: t('admin.experts'),
        href: '/app/admin/experts',
        icon: ExpertsIcon,
      },
      {
        title: t('admin.payouts'),
        href: '/app/admin/payouts',
        icon: TrendingUp,
      },
      {
        title: t('admin.booking_list'),
        href: '/app/admin/bookings',
        icon: BookingsIcon,
      },
      {
        title: t('admin.discount_list'),
        href: '/app/admin/discounts',
        icon: DiscountsIcon,
      },
      {
        title: t('admin.voucher_discount_list'),
        href: '/app/admin/voucher_discounts',
        icon: VoucherDiscountsIcon,
      },
      {
        title: t('admin.voucher_list'),
        href: '/app/admin/vouchers',
        icon: VoucherIcon,
      },
      {
        title: t('admin.banner_list'),
        href: '/app/admin/banners',
        icon: Percent,
      },
    ],
  },
  {
    subheader: t('expert_section'),
    role: 'expert',
    items: [
      {
        title: t('expert.earnings'),
        href: '/app/expert/earnings',
        icon: DollarSign,
      },
      {
        title: t('expert.bookings'),
        href: '/app/expert/bookings',
        icon: CalendarIcon,
      },
      {
        title: t('expert.booking_list'),
        href: '/app/expert/bookings/list',
        icon: ListIcon,
      },
      {
        title: t('expert.reviews'),
        href: '/app/expert/reviews',
        icon: ReviewsIcon,
      },
    ],
  },
  {
    subheader: t('pages'),
    role: [
      AdminPermissionRole.Associate,
      AdminPermissionRole.SuperAdmin,
      AdminPermissionRole.Sales,
      AdminPermissionRole.Manager,
    ],
    items: [
      {
        title: t('account'),
        href: '/app/account',
        icon: UserIcon,
      },
    ],
  },
];

const checkRole = (roles: PermissionRoles | PermissionRoles[], inputRole: PermissionRoles) =>
  Array.isArray(roles) ? roles.includes(inputRole) : inputRole === roles;

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
  role,
  notifications,
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
  role?: UserRole;
  notifications: Record<string, boolean>;
}): JSX.Element[] {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
        hasNotification={!!notifications[item.href]}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          role,
          notifications,
        })}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
        hasNotification={!!notifications[item.href]}
      />,
    );
  }

  return acc;
}

function renderNavItems({
  items,
  pathname,
  depth = 0,
  role,
  notifications,
}: {
  items: Item[];
  pathname: string;
  depth?: number;
  role?: UserRole;
  notifications: Record<string, boolean>;
}): JSX.Element {
  return (
    <List disablePadding>
      {items
        .filter((item) => !item.role || item.role === role)
        .reduce(
          (acc, item) =>
            reduceChildRoutes({
              acc,
              item,
              pathname,
              depth,
              role,
              notifications,
            }),
          [],
        )}
    </List>
  );
}

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const { permissionRole, role } = useAuth();
  const t = useContextTranslation('layout.navigation');

  const [notificationService] = useInject<INavNotificationService>(
    injectables.NavNotificationService,
  );

  const notifications = notificationService.useNotifications();

  // biome-ignore lint/correctness/useExhaustiveDependencies: It is required
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo className={styles.logo} />
            </RouterLink>
          </Box>
        </Hidden>
        <Divider />
        <Box p={2}>
          {sections(t)
            .filter((section) => {
              return checkRole(section.role, permissionRole);
            })
            .map((section) => (
              <List
                key={section.subheader}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                  role,
                  notifications,
                })}
              </List>
            ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: styles.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          className={styles.desktopDrawer}
          classes={{ paper: styles.desktopDrawerPaper }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
