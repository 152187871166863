import { gql, useQuery } from '@apollo/client';
import IPayoutAdapter, { PayoutExpertData } from 'domain/adapters/pageDataAdapters/IPayoutAdapter';
import { QueryResult } from 'domain/types/QueryResult';
import { ID } from 'domain/types/common';
import { useMemo } from 'react';
import client from 'utils/apollo';

const EXPERT_PAYOUT_DATA_QUERY = gql`
  query ExpertPayoutData($expertId: ID!) {
    expert(expertId: $expertId) {
      payoutEnabled
      iban
    }
  }
`;

const SET_MY_IBAN_MUTATION = gql`
  mutation SetMyIBAN($iban: String!) {
    setMyIban(iban: $iban)
  }
`;

const PayoutGQLAdapter: IPayoutAdapter = {
  useExpertPayoutData(expertId: ID): QueryResult<PayoutExpertData> {
    const query = useQuery(EXPERT_PAYOUT_DATA_QUERY, {
      variables: {
        expertId,
      },
      fetchPolicy: 'no-cache',
    });

    return useMemo(() => {
      if (!query.data?.expert) {
        return {
          error: !!query.error,
          refetch: query.refetch,
          loading: query.loading,
        };
      }
      return {
        error: !!query.error,
        refetch: query.refetch,
        loading: query.loading,
        data: query.data.expert,
      };
    }, [query.data, query.error, query.loading, query.refetch]);
  },
  async setMyIBAN(iban: string): Promise<void> {
    await client.mutate({
      mutation: SET_MY_IBAN_MUTATION,
      variables: {
        iban,
      },
      refetchQueries: ['ExpertPayoutData'],
    });
  },
};

export default PayoutGQLAdapter;
