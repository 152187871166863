import injectables from 'domain/adapters/injectables';
import IAdminAdapter from 'domain/adapters/pageDataAdapters/IAdminAdapter';
import IAuthAdapter from 'domain/adapters/pageDataAdapters/IAuthAdapter';
import IAvailabilityAdapter from 'domain/adapters/pageDataAdapters/IAvailabilityAdapter';
import IBookingAdapter from 'domain/adapters/pageDataAdapters/IBookingAdapter';
import IClientAdapter from 'domain/adapters/pageDataAdapters/IClientAdapter';
import IDiscountAdapter from 'domain/adapters/pageDataAdapters/IDiscountAdapter';
import IEarningsAdapter from 'domain/adapters/pageDataAdapters/IEarningsAdapter';
import IExpertAdapter from 'domain/adapters/pageDataAdapters/IExpertAdapter';
import IPayoutAdapter from 'domain/adapters/pageDataAdapters/IPayoutAdapter';
import IPayoutsAdapter from 'domain/adapters/pageDataAdapters/IPayoutsAdapter';
import IReviewAdapter from 'domain/adapters/pageDataAdapters/IReviewAdapter';
import ITreatmentTypeAdapter from 'domain/adapters/pageDataAdapters/ITreatmentTypeAdapter';
import IVoucherAdapter from 'domain/adapters/pageDataAdapters/IVoucherAdapter';
import BookingGQLAdapter from 'infrastructure/adapters/graphQLAdapters/BookingGQLAdapter';
import DiscountGQLAdapter from 'infrastructure/adapters/graphQLAdapters/DiscountGQLAdapter';
import PayoutsGQLAdapter from 'infrastructure/adapters/graphQLAdapters/PayoutsGQLAdapter';
import ReviewGQLAdapter from 'infrastructure/adapters/graphQLAdapters/ReviewGQLAdapter';
import TreatmentTypeGQLAdapter from 'infrastructure/adapters/graphQLAdapters/TreatmentTypeGQLAdapter';
import VoucherGQLAdapter from 'infrastructure/adapters/graphQLAdapters/VoucherGQLAdapter';
import { ContainerModule } from 'inversify';
import AdminGQLAdapter from './graphQLAdapters/AdminGQLAdapter';
import AuthGQLAdapter from './graphQLAdapters/AuthGQLAdapter';
import AvailabilityGQLAdapter from './graphQLAdapters/AvailabilityGQLAdapter';
import ClientGQLAdapter from './graphQLAdapters/ClientGQLAdapter';
import EarningsGQLAdapter from './graphQLAdapters/EarningsGQLAdapter';
import ExpertGQLAdapter from './graphQLAdapters/ExpertGQLAdapter';
import PayoutGQLAdapter from './graphQLAdapters/PayoutGQLAdapter';

export default new ContainerModule((bind) => {
  bind<IAdminAdapter>(injectables.AdminAdapter).toConstantValue(AdminGQLAdapter);
  bind<IExpertAdapter>(injectables.ExpertAdapter).toConstantValue(ExpertGQLAdapter);
  bind<IAvailabilityAdapter>(injectables.AvailabilityAdapter).toConstantValue(
    AvailabilityGQLAdapter,
  );
  bind<IClientAdapter>(injectables.ClientAdapter).toConstantValue(ClientGQLAdapter);
  bind<IAuthAdapter>(injectables.AuthAdapter).toConstantValue(AuthGQLAdapter);
  bind<ITreatmentTypeAdapter>(injectables.TreatmentTypeAdapter).toConstantValue(
    TreatmentTypeGQLAdapter,
  );
  bind<IBookingAdapter>(injectables.BookingAdapter).toConstantValue(BookingGQLAdapter);
  bind<IDiscountAdapter>(injectables.DiscountAdapter).toConstantValue(DiscountGQLAdapter);
  bind<IVoucherAdapter>(injectables.VoucherAdapter).toConstantValue(VoucherGQLAdapter);
  bind<IEarningsAdapter>(injectables.EarningsAdapter).toConstantValue(EarningsGQLAdapter);
  bind<IPayoutAdapter>(injectables.PayoutAdapter).toConstantValue(PayoutGQLAdapter);
  bind<IReviewAdapter>(injectables.ReviewAdapter).toConstantValue(ReviewGQLAdapter);
  bind<IPayoutsAdapter>(injectables.PayoutsAdapter).toConstantValue(PayoutsGQLAdapter);
});
