import LoginUserGetter from 'components/LoginUserGetter';
import SplashScreen from 'components/SplashScreen';
import { authStateAtom, userAtom } from 'contexts/AuthAtoms';
import { useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import type { FC, PropsWithChildren, ReactNode } from 'react';

interface IAuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<PropsWithChildren<IAuthProviderProps>> = ({ children }) => {
  const state = useAtomValue(authStateAtom);

  const setUser = useSetAtom(userAtom);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <>
      {state.role && state.userId && (
        <LoginUserGetter setUser={setUser} role={state.role} id={state.userId} />
      )}
      {state.isAuthenticated && !state.isUserLoaded ? <SplashScreen /> : children}
    </>
  );
};
