export const APP_VERSION = '1.0.0';

export const API_URL = process.env.REACT_APP_API_URL || 'https://api.testing.soulhouse.spinney.io';
export const CLIENT_URL =
  process.env.REACT_APP_CLIENT_URL || 'https://testing.soulhouse.spinney.io';
export const STRIPE_PAYMENT_DETAILS_URL =
  process.env.REACT_STRIPE_PAYMENT_DETAILS_URL || 'https://dashboard.stripe.com/test/payments/';

export const PAYPAL_PAYMENT_DETAILS_URL =
  process.env.REACT_APP_PAYPAL_PAYMENT_DETAILS_URL || 'https://www.paypal.com/activity/payment/';

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
};

export const BOOKING_START_HOUR = 7;
export const BOOKING_END_HOUR = 22.5;
