import { gql, useQuery } from '@apollo/client';
import IClientAdapter, {
  ClientShoppingCart,
  ClientStatistics,
  WithCount,
} from 'domain/adapters/pageDataAdapters/IClientAdapter';
import { BusinessClient } from 'domain/entities/BusinessClient';
import { Client, ClientOverview } from 'domain/entities/Client';
import { PaginatedResult, Pagination } from 'domain/types/Pagination';
import { QueryResult } from 'domain/types/QueryResult';
import { ID } from 'domain/types/common';
import { useMemo } from 'react';
import client from 'utils/apollo';

const GQL_ACTIONS = {
  GET_CLIENT_BY_ID: gql`
    query GetClientById($id: ID!, $bookingsPagination: Pagination) {
      client(id: $id, bookingsPagination: $bookingsPagination) {
        id
        name
        surname
        birthday
        email
        phoneNumber
        companyName
        blocked
        verified
        locale
        isPhantom
        statistics {
          totalSpend
          totalPaidBookings
          totalCanceledBookings
        }
        shoppingCarts {
          count
          nodes {
            customId
            id
            address {
              streetNumber
              locality
              street
              postalCode
            }
            time,
            length,
            status
            expert {
              name
            }
            treatmentType {
              name {
                lang
                value
              }
            }
            shoppingCart
          }
        }
      }
    }
  `,
  GET_ALL_CLIENTS: gql`
    query GetAllClients(
      $relationFilter: String,
      $blockedFilter: Boolean,
      $verifiedFilter:Boolean,
      $pagination: Pagination!,
      $sort: Sort
    ) {
      clients(
        relationFilter: $relationFilter,
        blockedFilter: $blockedFilter,
        verifiedFilter: $verifiedFilter,
        pagination: $pagination,
        sort: $sort
      ) {
        nodes {
          id
          name
          surname
          birthday
          email
          phoneNumber
          blocked
          verified
          statistics {
            totalSpend
            totalPaidBookings
            totalCanceledBookings
          }
        }
        count
      }
    }
  `,
  SET_CLIENT_BLOCKED_STATE: gql`
    mutation SetClientBlockedState($id: ID!, $blocked: Boolean!) {
      setClientBlockState(id: $id, blocked: $blocked)
    }
  `,
  CREATE_BUSINESS_CLIENT: gql`
    mutation CreateBusinessClient($input: BusinessClientCreationData!) {
      createBusinessClient(input: $input)
    }
  `,
  GET_CLIENT_OVERVIEWS: gql`
    query GetClientOverviews(
      $relationFilter: String
      $onlyBusiness: Boolean
      $sortBy: String
      $pagination: Pagination!
    ) {
      clientOverview(
        relationFilter: $relationFilter
        onlyBusiness: $onlyBusiness
        sortBy: $sortBy
        pagination: $pagination
      ) {
        clients {
          id
          name
          companyName
          surname
          email
          isPhantom
        }
        totalClients
      }
    }
  `,
};

const ClientGQLAdapter: IClientAdapter = {
  useClientDetails(
    id: ID,
    pagination?: Pagination,
  ): QueryResult<{
    client:
      | (Client & {
          shoppingCarts: WithCount<ClientShoppingCart[]>;
          statistics: ClientStatistics;
        })
      | null;
  }> {
    const query = useQuery<{
      client:
        | (Client & {
            shoppingCarts: WithCount<ClientShoppingCart[]>;
            statistics: ClientStatistics;
          })
        | null;
    }>(GQL_ACTIONS.GET_CLIENT_BY_ID, {
      variables: {
        id,
        bookingsPagination: pagination,
      },
    });
    return useMemo(
      () => ({
        error: !!query.error,
        loading: query.loading,
        refetch: query.refetch,
        ...(query.data?.client
          ? {
              data: {
                client: query.data?.client,
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },

  useClients(
    relationFilter,
    blockedFilter,
    verifiedFilter,
    pagination,
    sort,
  ): QueryResult<{
    clients: (Client & {
      statistics: ClientStatistics;
    })[];
    count: number;
  }> {
    const query = useQuery<{
      clients: {
        nodes: (Client & {
          statistics: ClientStatistics;
        })[];
        count: number;
      };
    }>(GQL_ACTIONS.GET_ALL_CLIENTS, {
      variables: {
        relationFilter: relationFilter?.length >= 2 ? relationFilter : null,
        blockedFilter,
        verifiedFilter,
        pagination,
        ...(sort
          ? {
              sort: {
                field: sort.field,
                order: sort.order,
              },
            }
          : {}),
      },
    });
    return useMemo(
      () => ({
        loading: query.loading,
        ...(query.data
          ? {
              data: {
                clients: query.data?.clients?.nodes || [],
                count: query.data?.clients?.count || 0,
              },
            }
          : {}),
        refetch: query.refetch,
        error: !!query.error,
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },

  async setClientBlockedState(id: ID, blocked: boolean): Promise<void> {
    await client.mutate({
      mutation: GQL_ACTIONS.SET_CLIENT_BLOCKED_STATE,
      variables: {
        id,
        blocked,
      },
      refetchQueries: ['GetAllClients', 'GetClientById'],
    });
  },

  async createBusinessClient(data: BusinessClient): Promise<ID> {
    const result = await client.mutate({
      mutation: GQL_ACTIONS.CREATE_BUSINESS_CLIENT,
      variables: {
        input: {
          companyName: data.companyName || null,
          email: data.email,
          locale: data.locale,
          name: data.name,
          phoneNumber: data.phoneNumber,
          surname: data.surname,
        },
      },
    });

    return result.data.createBusinessClient;
  },

  useClientOverviews(
    filters: { relationFilter?: string; sortBy?: string; onlyBusiness?: boolean },
    pagination: Pagination,
  ): QueryResult<PaginatedResult<{ clients: ClientOverview[] }>> {
    const { onlyBusiness, relationFilter, sortBy } = filters;
    const query = useQuery(GQL_ACTIONS.GET_CLIENT_OVERVIEWS, {
      variables: {
        relationFilter: relationFilter,
        onlyBusiness: onlyBusiness,
        sortBy: sortBy,
        pagination,
      },
    });

    return useMemo(
      () => ({
        error: !!query.error,
        loading: query.loading,
        refetch: query.refetch,
        ...(query.data
          ? {
              data: {
                count: query.data.clientOverview.totalClients,
                clients: query.data.clientOverview.clients,
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },
};

export default ClientGQLAdapter;
