import IExpertAdapter from 'domain/adapters/pageDataAdapters/IExpertAdapter';
import injectables from 'domain/injectables';
import INavNotificationService from 'domain/services/INavNotificationService';
import { useInject } from 'inversify-hooks';

const NavNotificationService: INavNotificationService = {
  useNotifications(): { '/app/admin/update_requests': boolean } {
    const [expertAdapter] = useInject<IExpertAdapter>(injectables.ExpertAdapter);

    const updateRequestsQuery = expertAdapter.useUpdateRequestCount();

    return {
      '/app/admin/update_requests': updateRequestsQuery.loading
        ? false
        : updateRequestsQuery.data?.count > 0,
    };
  },
};

export default NavNotificationService;
