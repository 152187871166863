import { gql, useQuery } from '@apollo/client';
import IAvailabilityAdapter from 'domain/adapters/pageDataAdapters/IAvailabilityAdapter';
import { CalendarAvailability, ISODate } from 'domain/types/IAvailability';
import { QueryResult } from 'domain/types/QueryResult';
import { ID } from 'domain/types/common';
import { useMemo } from 'react';
import client from 'utils/apollo';

interface QueryAvailability {
  start: ISODate;
  end: ISODate;
  expert: ID;
  id: ID;
}

const GQL_ACTIONS = {
  GET_MY_AVAILABILITIES: gql`
    query GetMyAvailabilities($day: Date!) {
      myAvailabilities(day: $day) {
        expert
        end
        start
        id
      }
    }
  `,
  GET_EXPERT_AVAILABILITIES: gql`
    query GetExpertAvailabilities($day: Date!, $expertId: ID!) {
      expertAvailabilities(day: $day, expertId: $expertId) {
        expert
        end
        start
        id
      }
    }
  `,
  SET_EXPERT_AVAILABILITIES: gql`
    mutation SetExpertAvailabilities(
      $day: Date!
      $availabilities: [AvailabilityInput!]!
      $expertId: ID
    ) {
      setAvailabilities(day: $day, availabilities: $availabilities, expertId: $expertId)
    }
  `,
};

const AvailabilityGQLAdapter: IAvailabilityAdapter = {
  useCalendarAvailabilities(
    selectedDate: Date,
  ): QueryResult<{ availabilities: CalendarAvailability[] }> {
    const query = useQuery<{ myAvailabilities: QueryAvailability[] }>(
      GQL_ACTIONS.GET_MY_AVAILABILITIES,
      {
        variables: {
          day: selectedDate,
        },
      },
    );

    return useMemo(
      () => ({
        refetch: query.refetch,
        loading: query.loading,
        error: !!query.error,
        ...(query.data?.myAvailabilities
          ? {
              data: {
                availabilities: query.data.myAvailabilities.map((av) => ({
                  start: av.start,
                  end: av.end,
                })),
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },

  async setAvailabilities(
    date: Date,
    availabilities: CalendarAvailability[],
    expertId?: ID,
  ): Promise<void> {
    const response = await client.mutate({
      mutation: GQL_ACTIONS.SET_EXPERT_AVAILABILITIES,
      variables: {
        day: date,
        availabilities: availabilities.map((av) => ({
          start: new Date(av.start),
          end: new Date(av.end),
        })),
        expertId,
      },
      refetchQueries: ['GetExpertAvailabilities'],
    });
    if (response.errors?.length) {
      throw new Error('Error while updating availabilities');
    }
  },

  useExpertAvailabilities(
    selectedDate: Date,
    expertId: ID,
  ): QueryResult<{ availabilities: CalendarAvailability[] }> {
    const query = useQuery<{ expertAvailabilities: QueryAvailability[] }>(
      GQL_ACTIONS.GET_EXPERT_AVAILABILITIES,
      {
        variables: {
          day: selectedDate,
          expertId,
        },
      },
    );

    return useMemo(
      () => ({
        refetch: query.refetch,
        loading: query.loading,
        error: !!query.error,
        ...(query.data?.expertAvailabilities
          ? {
              data: {
                availabilities: query.data.expertAvailabilities.map((av) => ({
                  start: av.start,
                  end: av.end,
                })),
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },
};

export default AvailabilityGQLAdapter;
