import { gql, useQuery } from '@apollo/client';
import IAuthAdapter, { GetUserResult } from 'domain/adapters/pageDataAdapters/IAuthAdapter';
import { LoginFields, UserRole } from 'domain/entities/IUser';
import { MutationResult } from 'domain/types/mutations';
import { useMemo } from 'react';
import client from 'utils/apollo';

const GQL_ACTIONS = {
  LOGIN_MUTATION: gql`
    mutation Login($email: String!, $password: String!, $role: Role!) {
      loginWithEmail(email: $email, password: $password, role: $role) {
        accessToken
      }
    }
  `,
  GET_AUTHENTICATED_DETAILS: gql`
    query GetDetails($id: ID!, $isAdmin: Boolean!) {
      admin(id: $id) @include(if: $isAdmin) {
        id
        email
        name
        verified
        blocked
      }
      expert(expertId: $id) @skip(if: $isAdmin) {
        id
        email
        name
        birthday
        media {
          type
          uri
        }
        description {
          lang
          value
        }
        profilePictureUrl
        experience {
          lang
          value
        }
        postalCodes
        verified
        payoutEnabled
        payoutActive
      }
    }
  `,
  REQUEST_RESET_PASSWORD_CODE: gql`
    mutation RequestResetPasswordCode($email: String!, $role: Role!) {
      sendPasswordResetCode(email: $email, role: $role)
    }
  `,
  RESET_PASSWORD: gql`
    mutation ResetPassword($code: String!, $email: String!, $role: Role!) {
      resetPassword(email: $email, code: $code, role: $role)
    }
  `,
  CHANGE_PASSWORD: gql`
    mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
      changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
    }
  `,
};

const AuthGQLAdapter: IAuthAdapter = {
  async login(values: LoginFields) {
    const response = await client.mutate({
      mutation: GQL_ACTIONS.LOGIN_MUTATION,
      variables: {
        ...values,
      },
    });
    return {
      data: response.data,
    };
  },

  useUser(id: string, role: UserRole): GetUserResult {
    const query = useQuery(GQL_ACTIONS.GET_AUTHENTICATED_DETAILS, {
      variables: {
        id,
        isAdmin: role === 'admin',
      },
    });
    return useMemo(
      () => ({
        loading: query.loading,
        error: !!query.error,
        data: {
          user: {
            ...(role === 'admin' ? query.data?.admin : query.data?.expert),
            role,
          },
        },
        refetch: query.refetch,
      }),
      [query.data, query.error, query.loading, query.refetch, role],
    );
  },

  async requestPasswordReset(email: string, role: UserRole): Promise<MutationResult<null>> {
    const response = await client.mutate({
      mutation: GQL_ACTIONS.REQUEST_RESET_PASSWORD_CODE,
      variables: {
        email,
        role,
      },
    });
    return {
      data: response.data,
    };
  },

  async resetPassword(code: string, email: string, role: UserRole): Promise<MutationResult<null>> {
    const response = await client.mutate({
      mutation: GQL_ACTIONS.RESET_PASSWORD,
      variables: {
        code,
        email,
        role,
      },
    });
    return {
      data: response.data,
    };
  },
  async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    await client.mutate({
      mutation: GQL_ACTIONS.CHANGE_PASSWORD,
      variables: {
        oldPassword,
        newPassword,
      },
    });
  },
};

export default AuthGQLAdapter;
