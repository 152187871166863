import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import englishTranslations from './languages/english.json';
import germanTranslations from './languages/german.json';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    lng: 'en',
    react: {
      useSuspense: false,
    },
    keySeparator: '.',
    resources: {
      en: { translation: englishTranslations },
      de: { translation: germanTranslations },
    },
    interpolation: { escapeValue: false },
  });

export const languages = ['en', 'de'];

export default i18n;
