import { authStateAtom } from 'contexts/AuthAtoms';
import { PermissionRoles } from 'domain/entities/Admin';
import { useAtomValue } from 'jotai';

const useAuth = () => useAtomValue(authStateAtom);

export const useCheckPermissionRole = (roles: PermissionRoles | PermissionRoles[]) => {
  const { permissionRole } = useAuth();
  return Array.isArray(roles) ? roles.includes(permissionRole) : permissionRole === roles;
};

export default useAuth;
