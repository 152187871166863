import { Box, Container, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import type { FC } from 'react';
import { useContextTranslation } from 'translation/useContextTranslation';
import styles from './Home.module.scss';

interface IHeroProps {
  className?: string;
}

const Hero: FC<IHeroProps> = ({ className, ...rest }) => {
  const t = useContextTranslation('page.home');

  return (
    <div className={clsx(styles.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
              <Typography variant="overline" color="secondary">
                {t('header.subtitle')}
              </Typography>
              <Typography variant="h1" color="textPrimary">
                {t('header.title')}
              </Typography>
              <Box mt={3}>
                <Typography variant="body1" color="textSecondary">
                  {t('header.text')}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box position="relative" display="flex" justifyContent="center" alignContent="center">
              <img className={styles.image} alt="Logo" src="/static/logo.png" />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Hero;
