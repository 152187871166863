import moment from 'moment';
import i18n, { languages } from 'translation/i18n';
import 'moment/locale/de';
import { injectable } from 'inversify';

import ILocaleService from 'domain/services/ILocaleService';

const LANGUAGE_STORAGE_KEY = 'saved_locale';

@injectable()
class StorageLocaleService implements ILocaleService {
  async initialize(): Promise<void> {
    const storedLanguageCode = localStorage.getItem(LANGUAGE_STORAGE_KEY);

    if (storedLanguageCode) {
      if (languages.includes(storedLanguageCode)) {
        await i18n.changeLanguage(storedLanguageCode);
        moment.locale(storedLanguageCode);
      } else {
        await i18n.changeLanguage('en');
        moment.locale('en');
      }
      return;
    }

    const deviceLanguageCode = navigator.language;
    const localeCode = deviceLanguageCode.substr(0, 2);
    localStorage.setItem(LANGUAGE_STORAGE_KEY, i18n.language);

    if (languages.includes(localeCode)) {
      await i18n.changeLanguage(localeCode);
      moment.locale(localeCode);
    } else {
      await i18n.changeLanguage('en');
      moment.locale('en');
    }
  }

  async setManualLanguage(language: string): Promise<void> {
    localStorage.setItem(LANGUAGE_STORAGE_KEY, language);
    await i18n.changeLanguage(language);
    moment.locale(language);
  }
}

export default StorageLocaleService;
