import { TranslatableStringLanguage } from 'domain/types/common';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export default function useTranslatableQueryFunction(): (
  translatable: TranslatableStringLanguage,
) => boolean {
  const { i18n } = useTranslation();

  return useCallback(
    (translatable: TranslatableStringLanguage) => i18n.language === translatable.lang,
    [i18n],
  );
}
