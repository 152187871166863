import PropTypes from 'prop-types';
import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

interface IAuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<IAuthGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { referrer: location.pathname },
        }}
      />
    );
  }

  return <>{children}</>;
};

AuthGuard.propTypes = { children: PropTypes.node };

export default AuthGuard;
