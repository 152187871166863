import { QueryResult as ApolloQueryResult } from '@apollo/client';

import { QueryResult } from 'domain/types/QueryResult';
import { useMemo } from 'react';

export default function useQueryMemo<T>(query: ApolloQueryResult, data?: T): QueryResult<T> {
  return useMemo(
    () => ({
      refetch: query.refetch,
      loading: query.loading,
      error: !!query.error,
      data,
    }),
    [data, query.error, query.loading, query.refetch],
  );
}
