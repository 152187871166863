import React, { useRef, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { authStateAtom } from 'contexts/AuthAtoms';
import useAuth from 'hooks/useAuth';
import { useSetAtom } from 'jotai';
import { useSnackbar } from 'notistack';
import { useContextTranslation } from 'translation/useContextTranslation';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: { width: 200 },
}));

const Account: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const t = useContextTranslation('layout.topbar');
  const ref = useRef<any>(null);
  const { user } = useAuth();
  const logout = useSetAtom(authStateAtom);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      logout({ type: 'LOGOUT' });
      history.push('/');
    } catch (_err) {
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        // @ts-ignore
        ref={ref}
      >
        <Avatar alt="User" className={classes.avatar} src="" />
        <Hidden smDown>
          <Typography variant="h6" color="textSecondary">
            {user?.name}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem component={RouterLink} to="/app/account">
          {t('account_link')}
        </MenuItem>
        <MenuItem onClick={handleLogout}>{t('logout_btn')}</MenuItem>
      </Menu>
    </>
  );
};

export default Account;
