import { gql, useQuery } from '@apollo/client';
import ITreatmentTypeAdapter from 'domain/adapters/pageDataAdapters/ITreatmentTypeAdapter';
import { TreatmentType, TreatmentTypeInfoPrice } from 'domain/entities/TreatmentType';
import { PaginatedResult, Pagination } from 'domain/types/Pagination';
import { QueryResult } from 'domain/types/QueryResult';
import { Entity, ID, TranslatableString } from 'domain/types/common';
import { useMemo } from 'react';

const GQL_ACTIONS = {
  GET_ALL_TREATMENT_TYPES: gql`
    query GetAllTreatmentTypes($pagination: Pagination! $isSpaTypeFilter: Boolean) {
      treatmentTypes(pagination: $pagination, isSpaTypeFilter: $isSpaTypeFilter) {
        nodes {
          id
          prices {
            length
            afternoon {
              payout
              price
            }
            morning {
              payout
              price
            }
            evening {
              payout
              price
            }
          }
          name {
            lang
            value
          }
          media {
            type
          }
          description {
            lang
            value
          }
          variant
          isLocationOnly
        }
        count
      }
    }
  `,
  GET_TREATMENT_TYPE_BY_ID: gql`
    query GetTreatmentTypeById($id: ID!) {
      treatmentType(treatmentTypeId: $id) {
        id
        enabled

        name {
          lang
          value
        }
        description {
          lang
          value
        }
        longDescription {
          lang
          value
        }
        details {
          level {
            lang
            value
          }
          oil
          origin {
            lang
            value
          }
          pressure {
            lang
            value
          }
        }
        prices {
          length
          morning {
            payout
            price
          }
          afternoon {
            payout
            price
          }
          evening {
            payout
            price
          }
        }
        media {
          type
          uri
        }
        openedHours {
          end {
            hour
            minutes
          }
          start {
            minutes
            hour
          }
        }
        variant
        isLocationOnly
      }
    }
  `,
  GET_TREATMENT_TYPES_INFO: gql`
    query GetTreatmentTypesInfo {
      treatmentTypeInfo(ignoreOnlySpa: true, unsafeOmitLocationOnly: true) {
        id
        name {
          lang
          value
        }
        prices {
          afternoon
          evening
          morning
          length
        }

      }
      locationTreatmentTypes {
        id
        name {
          lang
          value
        }
        prices {
          afternoon
          evening
          morning
          length
        }
      }
    }
  `,
};

const TreatmentTypeGQLAdapter: ITreatmentTypeAdapter = {
  useTreatmentTypes(
    pagination: Pagination,
    isSpaTypeFilter?: boolean,
  ): QueryResult<PaginatedResult<{ treatmentTypes: Entity<TreatmentType>[] }>> {
    const query = useQuery<{ treatmentTypes: PaginatedResult<{ nodes: Entity<TreatmentType>[] }> }>(
      GQL_ACTIONS.GET_ALL_TREATMENT_TYPES,
      {
        variables: {
          pagination,
          isSpaTypeFilter,
        },
        fetchPolicy: 'network-only',
      },
    );
    return useMemo(
      () => ({
        refetch: query.refetch,
        loading: query.loading,
        error: !!query.error,
        ...(query.data?.treatmentTypes
          ? {
              data: {
                count: query.data.treatmentTypes?.count || 0,
                treatmentTypes: query.data.treatmentTypes?.nodes || [],
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },

  useTreatmentType(id: ID): QueryResult<{ treatmentType: Entity<TreatmentType> | null }> {
    const query = useQuery(GQL_ACTIONS.GET_TREATMENT_TYPE_BY_ID, {
      variables: {
        id,
      },
    });
    return useMemo(
      () => ({
        refetch: query.refetch,
        loading: query.loading,
        error: !!query.error,
        ...(query.data?.treatmentType
          ? {
              data: {
                treatmentType: {
                  ...query.data.treatmentType,
                  openedHours: query.data.treatmentType.openedHours?.[0] && {
                    start: {
                      hour: query.data.treatmentType.openedHours[0].start.hour,
                      minutes: query.data.treatmentType.openedHours[0].start.minutes,
                    },
                    end: {
                      hour: query.data.treatmentType.openedHours[0].end.hour,
                      minutes: query.data.treatmentType.openedHours[0].end.minutes,
                    },
                  },
                },
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },

  useTreatmentTypesInfo(): QueryResult<{
    treatmentTypes: Entity<{ name: TranslatableString; prices: TreatmentTypeInfoPrice[] }>[];
  }> {
    const query = useQuery<{
      treatmentTypeInfo: Entity<{ name: TranslatableString; prices: TreatmentTypeInfoPrice[] }>[];
      locationTreatmentTypes: any[];
    }>(GQL_ACTIONS.GET_TREATMENT_TYPES_INFO);
    return useMemo(
      () => ({
        refetch: query.refetch,
        loading: query.loading,
        error: !!query.error,
        ...(query.data
          ? {
              data: {
                treatmentTypes: query.data.treatmentTypeInfo,
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },
};

export default TreatmentTypeGQLAdapter;
