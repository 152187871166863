import { AdminPermissionRole, PermissionRoles } from 'domain/entities/Admin';
import PropTypes from 'prop-types';
import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

interface AdminGuardProps {
  children?: ReactNode;
  allowedRole?: PermissionRoles | PermissionRoles[];
}

const checkRole = (roles: PermissionRoles | PermissionRoles[], inputRole: PermissionRoles) =>
  Array.isArray(roles) ? roles.includes(inputRole) : inputRole === roles;

const AdminGuard: FC<AdminGuardProps> = ({
  allowedRole = AdminPermissionRole.SuperAdmin,
  children,
}) => {
  const { permissionRole } = useAuth();

  if (!checkRole(allowedRole, permissionRole)) {
    return <Redirect to="/403" />;
  }

  return <>{children}</>;
};

AdminGuard.propTypes = { children: PropTypes.node };

export default AdminGuard;
