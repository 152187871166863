import injectables from 'domain/injectables';
import IGeocodingService, { GeocodingServiceType } from 'domain/services/IGeocodingService';
import IImageValidationService from 'domain/services/IImageValidationService';
import ILocaleService from 'domain/services/ILocaleService';
import INavNotificationService from 'domain/services/INavNotificationService';
import GoogleGeocodingService from 'infrastructure/services/GoogleGeocodingService';
import ImageValidationService from 'infrastructure/services/ImageValidationService';
import StorageLocaleService from 'infrastructure/services/StorageLocaleService';
import { ContainerModule } from 'inversify';
import NavNotificationService from './NavNotificationService';

export default new ContainerModule((bind) => {
  bind<IImageValidationService>(injectables.ImageValidationService).to(ImageValidationService);
  bind<INavNotificationService>(injectables.NavNotificationService).toConstantValue(
    NavNotificationService,
  );
  bind<ILocaleService>(injectables.LocaleService).to(StorageLocaleService);
  bind<IGeocodingService>(GeocodingServiceType).to(GoogleGeocodingService).inSingletonScope();
});
