import { gql, useQuery } from '@apollo/client';
import currency from 'currency.js';
import { parseISO } from 'date-fns';
import IPayoutsAdapter, { BookingOverview } from 'domain/adapters/pageDataAdapters/IPayoutsAdapter';
import { Expert } from 'domain/entities/Expert';
import Payout, { BookingPayoutStatus } from 'domain/entities/Payout';
import { PaginatedResult, Pagination } from 'domain/types/Pagination';
import { QueryResult } from 'domain/types/QueryResult';
import TimeRange from 'domain/types/TimeRange';
import { ID } from 'domain/types/common';
import { useMemo } from 'react';
import client from 'utils/apollo';

const GQL_ACTIONS = {
  GET_EXPERT_PAYOUTS_IN_RANGE: gql`
    query GetExpertPayoutsInRange(
      $name: String
      $surname: String
      $pagination: Pagination!
      $timeRange: TimeRange!
      $status: String!
    ) {
      expertsPayouts(
        nameFilter: $name
        surnameFilter: $surname
        timeRange: $timeRange
        pagination: $pagination
        status: $status
      ) {
        count
        nodes {
          customId
          expertName
          expertId
          id
          total
          payoutStatus
        }
      }
    }
  `,
  GET_EXPERT_PAYOUT_OVERVIEW: gql`
    query GetExpertPayoutOverview($expertId: ID!, $timeRange: TimeRange!) {
      expertPayoutDetails(expertId: $expertId, timeRange: $timeRange) {
        bookingsOverview {
          length
          payout
          time
          payoutStatus
          id
          shoppingCart
        }
        expert {
          customId
          name
          email
          profilePictureUrl
        }
      }
    }
  `,

  CREATE_MANUAL_PAYOUTS_FOR_TIME_RANGE: gql`
    mutation CreateManualPayoutsForTimeRange(
      $timeRange: TimeRange!
      $nameFilter: String
      $surnameFilter: String
    ) {
      createManualPayoutsForTimeRange(
        timeRange: $timeRange
        nameFilter: $nameFilter
        surnameFilter: $surnameFilter
      )
    }
  `,

  DELETE_BOOKING_PAYOUT: gql`
    mutation DeleteBookingPayout($bookingId: ID!) {
      deleteBookingPayout(bookingId: $bookingId)
    }
  `,
};

const PayoutsGQLAdapter: IPayoutsAdapter = {
  useExpertPayouts(
    filters: {
      nameFilter?: string;
      surnameFilter?: string;
      timeRange: TimeRange;
      statusFilter: BookingPayoutStatus | 'all';
    },
    pagination: Pagination,
  ): QueryResult<PaginatedResult<{ expertsPayouts: Payout[] }>> {
    const { timeRange, nameFilter: name, surnameFilter: surname, statusFilter } = filters;
    const query = useQuery<{
      expertsPayouts: PaginatedResult<{ nodes: (Payout & { expertId: ID })[] }>;
    }>(GQL_ACTIONS.GET_EXPERT_PAYOUTS_IN_RANGE, {
      variables: {
        name: name?.length >= 2 ? name : null,
        surname: surname?.length >= 2 ? surname : null,
        timeRange,
        pagination,
        status: statusFilter,
      },
    });

    return useMemo(
      () => ({
        refetch: query.refetch,
        loading: query.loading,
        error: !!query.error,
        ...(query.data?.expertsPayouts
          ? {
              data: {
                count: query.data.expertsPayouts?.count || 0,
                expertsPayouts: query.data.expertsPayouts.nodes.map((expertPayout) => ({
                  id: expertPayout.expertId,
                  payoutStatus: expertPayout.payoutStatus,
                  expertName: expertPayout.expertName,
                  customId: expertPayout.customId,
                  total: currency(expertPayout.total),
                })),
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },

  usePayoutDetails(
    id: ID,
    timeRange: TimeRange,
  ): QueryResult<{
    expert: Expert;
    bookingsOverview: BookingOverview[];
  }> {
    const query = useQuery(GQL_ACTIONS.GET_EXPERT_PAYOUT_OVERVIEW, {
      variables: {
        timeRange: {
          fromDate: timeRange.fromDate,
          toDate: timeRange.toDate,
        },
        expertId: id,
      },
    });

    return useMemo(
      () => ({
        refetch: query.refetch,
        loading: query.loading,
        error: !!query.error,
        ...(query.data?.expertPayoutDetails
          ? {
              data: {
                expert: query.data.expertPayoutDetails.expert,
                bookingsOverview: query.data.expertPayoutDetails.bookingsOverview.map(
                  (overview) => ({
                    ...overview,
                    payout: currency(overview.payout),
                    time: parseISO(overview.time),
                  }),
                ),
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },

  async createManualPayoutsForTimeRange(filters: {
    timeRange: TimeRange;
    nameFilter?: string;
    surnameFilter?: string;
  }): Promise<void> {
    const { timeRange, nameFilter, surnameFilter } = filters;
    await client.mutate({
      mutation: GQL_ACTIONS.CREATE_MANUAL_PAYOUTS_FOR_TIME_RANGE,
      variables: {
        timeRange,
        nameFilter,
        surnameFilter,
      },
      refetchQueries: ['GetExpertPayoutsInRange'],
    });
  },

  async deleteBookingPayout(bookingId: ID): Promise<void> {
    await client.mutate({
      mutation: GQL_ACTIONS.DELETE_BOOKING_PAYOUT,
      variables: {
        bookingId,
      },
      refetchQueries: ['GetExpertPayoutOverview', 'GetExpertPayoutsInRange'],
    });
  },
};

export default PayoutsGQLAdapter;
