import { gql, useQuery } from '@apollo/client';
import IAdminAdapter, { AdminSort } from 'domain/adapters/pageDataAdapters/IAdminAdapter';
import { Admin } from 'domain/entities/Admin';
import { ExpertUpdateValues } from 'domain/entities/Expert';
import {
  TreatmentTypeCreateValues,
  TreatmentTypeUpdateValues,
} from 'domain/entities/TreatmentType';
import { PaginatedResult, Pagination } from 'domain/types/Pagination';
import { QueryResult } from 'domain/types/QueryResult';
import { Sort } from 'domain/types/Sort';
import { ID } from 'domain/types/common';
import { useMemo } from 'react';
import client from 'utils/apollo';

const GQL_ACTIONS = {
  GET_ALL_ADMIN_USERS: gql`
    query GetAllAdminUsers($name: String, $sort: Sort, $pagination: Pagination!) {
      admins(name: $name, sort: $sort, pagination: $pagination) {
        nodes {
          id
          email
          name
          permissionRole
          blocked
        }
        count
      }
    }
  `,
};
const GQL_MUTATIONS = {
  CREATE_TREATMENT_TYPE: gql`
    mutation CreateTreatmentType($input: TreatmentTypeCreationData!) {
      createTreatmentType(inputData: $input)
    }
  `,
  CREATE_EXPERT: gql`
    mutation CreateExpert($input: ExpertCreationData!) {
      createExpert(expert: $input)
    }
  `,
  UPDATE_EXPERT: gql`
    mutation UpdateExpert($id: ID!, $input: ExpertAdminUpdateData!) {
      updateExpert(id: $id, expert: $input)
    }
  `,
  ACCEPT_UPDATE_REQUEST: gql`
    mutation AcceptUpdateRequest($expertId: ID!) {
      acceptUpdateRequest(expertId: $expertId)
    }
  `,
  REJECT_UPDATE_REQUEST: gql`
    mutation RejectUpdateRequest($expertId: ID!) {
      rejectUpdateRequest(expertId: $expertId)
    }
  `,
  UPDATE_TREATMENT_TYPE: gql`
    mutation UpdateTreatmentType($id: ID!, $input: TreatmentTypeUpdateData!) {
      updateTreatmentType(treatmentTypeId: $id, updateData: $input)
    }
  `,
  CREATE_ADMIN_USER: gql`
    mutation createAdminUser($input: AdminCreationData!) {
      createAdminUser(admin: $input)
    }
  `,
};

const AdminGQLAdapter: IAdminAdapter = {
  async createTreatmentType(input: TreatmentTypeCreateValues): Promise<ID> {
    const response = await client.mutate<{ createTreatmentType: ID }>({
      mutation: GQL_MUTATIONS.CREATE_TREATMENT_TYPE,
      variables: {
        input,
      },
      refetchQueries: ['GetAllTreatmentTypes'],
    });
    if (!response.data) {
      throw new Error('No id returned for create treatment type');
    }
    return response.data.createTreatmentType;
  },

  async createExpert(input) {
    const response = await client.mutate<ID>({
      mutation: GQL_MUTATIONS.CREATE_EXPERT,
      variables: {
        input,
      },
      refetchQueries: ['GetAllExperts'],
    });
    if (!response.data) {
      throw new Error('No id returned');
    }
    return response.data;
  },

  async updateExpert(id: ID, values: Partial<Omit<ExpertUpdateValues, 'id'>>): Promise<any> {
    const response = await client.mutate({
      mutation: GQL_MUTATIONS.UPDATE_EXPERT,
      variables: {
        id,
        input: values,
      },
      // refetchQueries: ['GetExpertById'],
    });
    if (!response.data) {
      throw new Error('Error while updating');
    }
    return response.data;
  },

  async acceptUpdateRequest(expertId: ID): Promise<ID> {
    const response = await client.mutate<ID>({
      mutation: GQL_MUTATIONS.ACCEPT_UPDATE_REQUEST,
      variables: {
        expertId,
      },
      refetchQueries: ['GetAllUpdateRequests'],
    });
    if (!response.data) {
      throw new Error('No id returned');
    }
    return response.data;
  },

  async rejectUpdateRequest(expertId: ID): Promise<ID> {
    const response = await client.mutate<ID>({
      mutation: GQL_MUTATIONS.REJECT_UPDATE_REQUEST,
      variables: {
        expertId,
      },
      refetchQueries: ['GetAllUpdateRequests'],
    });
    if (!response.data) {
      throw new Error('No id returned');
    }
    return response.data;
  },

  async updateTreatmentType(id: ID, input: TreatmentTypeUpdateValues): Promise<void> {
    const response = await client.mutate<{ createTreatmentType: null }>({
      mutation: GQL_MUTATIONS.UPDATE_TREATMENT_TYPE,
      variables: {
        id,
        input,
      },
      refetchQueries: ['GetAllTreatmentTypes', 'GetTreatmentTypeById'],
    });
    if (response.errors?.length) {
      throw new Error('Cannot update treatment type');
    }
  },

  useAdmins(
    name: string | null,
    pagination: Pagination,
    sort: Sort<AdminSort> | null,
  ): QueryResult<PaginatedResult<{ adminUsers: Admin[] }>> {
    const query = useQuery<{ admins: PaginatedResult<{ nodes: Admin[] }> }>(
      GQL_ACTIONS.GET_ALL_ADMIN_USERS,
      {
        variables: {
          name: name?.length >= 2 ? name : null,
          pagination,
          ...(sort
            ? {
                sort: {
                  field: sort.field,
                  order: sort.order,
                },
              }
            : {}),
        },
      },
    );

    return useMemo(
      () => ({
        refetch: query.refetch,
        loading: query.loading,
        error: !!query.error,
        ...(query.data?.admins
          ? {
              data: {
                count: query.data.admins?.count || 0,
                adminUsers: query.data.admins?.nodes || [],
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },

  async createAdminUser(input) {
    const response = await client.mutate<ID>({
      mutation: GQL_MUTATIONS.CREATE_ADMIN_USER,
      variables: {
        input,
      },
      refetchQueries: ['GetAllAdminUsers'],
    });
    if (!response.data) {
      throw new Error('No id returned');
    }
    return response.data;
  },
};

export default AdminGQLAdapter;
