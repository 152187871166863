import { Expert } from 'domain/entities/Expert';
import PropTypes from 'prop-types';
import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

interface ExpertGuardProps {
  children?: ReactNode;
}

const ExpertGuard: FC<ExpertGuardProps> = ({ children }) => {
  const { role, user } = useAuth();

  if (role !== 'expert') {
    return <Redirect to="/403" />;
  }

  if ((user as Expert).blocked) {
    return <Redirect to="/account_blocked" />;
  }

  return <>{children}</>;
};

ExpertGuard.propTypes = { children: PropTypes.node };

export default ExpertGuard;
