import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'prismjs/prism';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'nprogress/nprogress.css';
import 'assets/css/prism.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './container';
import { ApolloProvider } from '@apollo/client';
import App from 'App';
import { SettingsProvider } from 'contexts/SettingsContext';
import { enableES5 } from 'immer';
import * as serviceWorker from 'serviceWorker';
import client from './utils/apollo';

enableES5();

ReactDOM.render(
  <SettingsProvider>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </SettingsProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
