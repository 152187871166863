import { AppBar, Box, Hidden, IconButton, SvgIcon, Toolbar } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import type { FC } from 'react';
import { Menu as MenuIcon } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import Account from './Account';
import Settings from './Settings';
import styles from './TopBar.module.scss';

interface ITopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

const TopBar: FC<ITopBarProps> = ({ onMobileNavOpen, ...rest }) => {
  return (
    <AppBar className={classNames(styles.root)} {...rest} color="secondary">
      <Toolbar className={styles.toolbar}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <Box m={1}>
            <RouterLink to="/">
              <img className={styles.logo} alt="Logo" src="/static/logo_horizontal.png" />
            </RouterLink>
          </Box>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <Settings />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
