import { Loader } from '@googlemaps/js-api-loader';
import { googleConfig } from 'config';
import IGeocodingService from 'domain/services/IGeocodingService';
import { BookingAddress } from 'domain/types/Booking';
import { injectable } from 'inversify';

@injectable()
export default class GoogleGeocodingService implements IGeocodingService {
  private geocoder: google.maps.Geocoder | null = null;

  constructor() {
    const loader = new Loader({
      apiKey: googleConfig.apiKey,
      libraries: ['places'],
      version: 'weekly',
    });

    loader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });
  }

  async geocode(address: string): Promise<BookingAddress[]> {
    if (!this.geocoder) {
      throw new Error('Google Maps API is not loaded');
    }

    const { results } = await this.geocoder.geocode({ address, region: 'de' });

    return (
      results
        ?.map((result) => ({
          postalCode: result.address_components.find((component) =>
            component.types.includes('postal_code'),
          )?.long_name,
          streetNumber: result.address_components.find((component) =>
            component.types.includes('street_number'),
          )?.long_name,
          street: result.address_components.find((component) => component.types.includes('route'))
            ?.long_name,
          locality: result.address_components.find((component) =>
            component.types.includes('locality'),
          )?.long_name,
        }))
        ?.filter((r) => r.street || r.streetNumber || r.locality || r.postalCode) || []
    );
  }
}
