import { gql } from '@apollo/client';
import { authStateAtom, lastRefreshedAtom } from 'contexts/AuthAtoms';
import IAuthAdapter from 'domain/adapters/pageDataAdapters/IAuthAdapter';
import { Admin } from 'domain/entities/Admin';
import { Expert } from 'domain/entities/Expert';
import { UserRole } from 'domain/entities/IUser';
import injectables from 'domain/injectables';
import { useInject } from 'inversify-hooks';
import { useAtom, useSetAtom } from 'jotai';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import client from 'utils/apollo';

interface Props {
  setUser: (user: Expert | Admin) => void;
  role: UserRole;
  id: string;
}

const REFRESH_TOKEN_ACTION = gql`
  mutation RefreshToken {
    refreshAccessToken {
      accessToken
    }
  }
`;

const refreshToken = async () => {
  const response = await client.mutate({
    mutation: REFRESH_TOKEN_ACTION,
  });
  if (response.data) {
    return response.data.refreshAccessToken.accessToken;
  }

  return null;
};

function LoginUserGetter({ setUser, role, id }: Props) {
  const [lastRefreshed, setLastRefreshed] = useAtom(lastRefreshedAtom);

  const [authAdapter] = useInject<IAuthAdapter>(injectables.AuthAdapter);
  const query = authAdapter.useUser(id, role);
  const setAuthState = useSetAtom(authStateAtom);
  useEffect(() => {
    if (!query.loading && query.error) {
      setAuthState({ type: 'LOGOUT' });
    }
    if (!query.loading && query.data.user) {
      setUser(query.data.user);
    }
  }, [query.loading, query.data, query.error, setUser, setAuthState]);

  useEffect(() => {
    const updateLastRefreshedCallback = () => {
      setLastRefreshed((p) => p + 1);
    };

    let updateLastRefreshed = setInterval(updateLastRefreshedCallback, 1000);

    let isSet = true;

    const onHideListener = () => {
      if (document.hidden) {
        if (isSet) {
          clearInterval(updateLastRefreshed);
          updateLastRefreshed = null;
          isSet = false;
        }
      } else {
        if (!isSet) {
          updateLastRefreshed = setInterval(updateLastRefreshedCallback, 1000);
          isSet = true;
        }
      }
    };

    document.addEventListener('visibilitychange', onHideListener);
    return () => {
      if (isSet) {
        clearInterval(updateLastRefreshed);
      }
      document.removeEventListener('visibilitychange', onHideListener);
    };
  }, [setLastRefreshed]);

  useEffect(() => {
    if (lastRefreshed > 60 * 15) {
      refreshToken().then((accessToken) => {
        if (accessToken) {
          setAuthState({ type: 'REFRESH_TOKEN', token: accessToken });
          setLastRefreshed(0);
        }
      });
    }
  }, [lastRefreshed, setLastRefreshed, setAuthState]);

  return <></>;
}

LoginUserGetter.propTypes = {
  role: PropTypes.oneOf(['expert', 'admin']).isRequired,
  id: PropTypes.string.isRequired,
  setUser: PropTypes.func.isRequired,
};

export default LoginUserGetter;
