import AdminGuard from 'components/AdminGuard';
import AuthGuard from 'components/AuthGuard';
import ExpertGuard from 'components/ExpertGuard';
import GuestGuard from 'components/GuestGuard';
import LoadingScreen from 'components/LoadingScreen';
import { AdminPermissionRole } from 'domain/entities/Admin';
import DashboardLayout from 'layouts/DashboardLayout';
import MainLayout from 'layouts/MainLayout';
/* eslint-disable react/no-array-index-key */
import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import HomeView from 'views/home/HomeView';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
  guardProps?: any;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const guardProps = route.guardProps || [];
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard {...guardProps}>
                <Layout>
                  {route.routes ? renderRoutes(route.routes) : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('views/errors/NotFoundView')),
  },
  {
    exact: true,
    path: '/callback/stripeOnboarding',
    component: () => <Redirect to="/app/account" />,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('views/auth/LoginView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/reset_password',
    component: lazy(() => import('views/auth/ResetPasswordView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/reset_password/confirm',
    component: lazy(() => import('views/auth/ResetPasswordConfirmView')),
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        path: '/app/admin',
        guard: AdminGuard,
        guardProps: {
          allowedRole: [
            AdminPermissionRole.SuperAdmin,
            AdminPermissionRole.Manager,
            AdminPermissionRole.Sales,
            AdminPermissionRole.Associate,
          ],
        },
        routes: [
          {
            exact: true,
            path: '/app/admin/stats',
            component: lazy(() => import('views/statistics')),
          },
          {
            exact: true,
            path: '/app/admin/payouts/:expertId',
            component: lazy(() => import('views/payouts/PayoutDetailsView')),
          },
          {
            exact: true,
            path: '/app/admin/payouts',
            component: lazy(() => import('views/payouts/PayoutsListView')),
          },
          {
            exact: true,
            path: '/app/admin/banners',
            component: lazy(() => import('views/banners/BannerListView')),
          },
          {
            exact: true,
            path: '/app/admin/vouchers/new',
            component: lazy(() => import('views/vouchers/VoucherAddView')),
          },
          {
            exact: true,
            path: '/app/admin/vouchers/:voucherId/edit',
            component: lazy(() => import('views/vouchers/VoucherEditView')),
          },
          {
            exact: true,
            path: '/app/admin/vouchers/:voucherId',
            component: lazy(() => import('views/vouchers/VoucherDetailsView')),
          },
          {
            exact: true,
            path: '/app/admin/vouchers',
            component: lazy(() => import('views/vouchers/VoucherListView')),
          },
          {
            exact: true,
            path: '/app/admin/update-vouchers',
            component: lazy(() => import('views/vouchers/UpdateManyVouchers/UpdateManyVouchers')),
          },

          {
            exact: true,
            path: '/app/admin/discounts/new',
            component: lazy(() => import('views/discounts/DiscountAddView')),
          },
          {
            exact: true,
            path: '/app/admin/discounts/:discountId/edit',
            component: lazy(() => import('views/discounts/DiscountEditView')),
          },
          {
            exact: true,
            path: '/app/admin/discounts/:discountId',
            component: lazy(() => import('views/discounts/DiscountDetailsView')),
          },
          {
            exact: true,
            path: '/app/admin/bookings/:cartId/:bookingId/edit',
            component: lazy(() => import('views/bookings/EditBookingView')),
          },
          {
            exact: true,
            path: '/app/admin/bookings/create',
            component: lazy(() => import('views/bookings/CreateBookingView')),
          },
          {
            exact: true,
            path: '/app/admin/bookings/:cartId/:bookingId',
            component: lazy(() => import('views/bookings/AdminBookingDetailsView')),
          },
          {
            exact: true,
            path: '/app/admin/carts/create',
            component: lazy(() => import('views/bookings/CreateMultiBookingView')),
          },
          {
            exact: true,
            path: '/app/admin/carts/:cartId/edit',
            component: lazy(() => import('views/bookings/EditMultiBookingView')),
          },
          {
            exact: true,
            path: '/app/admin/carts/:cartId',
            component: lazy(() => import('views/bookings/ShoppingCartDetailsView')),
          },
          {
            exact: true,
            guardProps: { allowedRole: ['admin', 'manager'] },
            path: '/app/admin/treatmentTypes/:treatmentTypeId/edit',
            component: lazy(() => import('views/treatmentTypes/TreatmentTypeEditView')),
          },
          {
            exact: true,
            path: '/app/admin/locations/create',
            component: lazy(() => import('views/locations/LocationAddView')),
          },
          {
            exact: true,
            path: '/app/admin/locations',
            component: lazy(() => import('views/locations/LocationsTableView')),
          },
          {
            exact: true,
            path: '/app/admin/locations/:locationId/availability',
            component: lazy(() => import('views/locations/LocationAvailabilityView')),
          },
          {
            exact: true,
            path: '/app/admin/locations/:locationId/discounts',
            component: lazy(() => import('views/locations/LocationDiscountsView')),
          },
          {
            exact: true,
            path: '/app/admin/locations/:locationId/treatments',
            component: lazy(() => import('views/locations/LocationTreatmentsView')),
          },
          {
            exact: true,
            path: '/app/admin/locations/:locationId/edit',
            component: lazy(() => import('views/locations/LocationEditView')),
          },
          {
            exact: true,
            path: '/app/admin/treatmentTypes/create',
            component: lazy(() => import('views/treatmentTypes/TreatmentTypeAddView')),
          },
          {
            exact: true,
            path: '/app/admin/treatmentTypes',
            component: lazy(() => import('views/treatmentTypes/TreatmentTypeListView')),
          },
          {
            exact: true,
            path: '/app/admin/treatmentSelections',
            component: lazy(() => import('views/selections/TreatmentSelectionList')),
          },
          {
            exact: true,
            path: '/app/admin/promoCodeSelections',
            component: lazy(() => import('views/selections/PromoCodeSelectionList')),
          },
          {
            exact: true,
            path: '/app/admin/treatmentSelections/add',
            component: lazy(() => import('views/selections/TreatmentSelectionAdd')),
          },
          {
            exact: true,
            path: '/app/admin/promoCodeSelections/add',
            component: lazy(() => import('views/selections/PromoCodeSelectionAdd')),
          },
          {
            exact: true,
            path: '/app/admin/treatmentSelections/:selectionId/edit',
            component: lazy(() => import('views/selections/TreatmentSelectionEdit')),
          },
          {
            exact: true,
            path: '/app/admin/promoCodeSelections/:selectionId/edit',
            component: lazy(() => import('views/selections/PromoCodeSelectionEdit')),
          },
          {
            exact: true,
            path: '/app/admin/update_requests/:updateRequestId',
            component: lazy(() => import('views/updateRequests/UpdateRequestDetailsView')),
          },
          {
            exact: true,
            path: '/app/admin/update_requests',
            component: lazy(() => import('views/updateRequests/UpdateRequestListView')),
          },
          {
            exact: true,
            path: '/app/admin/experts/create',
            component: lazy(() => import('views/experts/ExpertAddView')),
          },
          {
            exact: true,
            path: '/app/admin/experts/:expertId/edit',
            component: lazy(() => import('views/experts/ExpertEditView')),
          },
          {
            exact: true,
            path: '/app/admin/experts/:expertId/locations',
            component: lazy(() => import('views/experts/ExpertLocationView')),
          },
          {
            exact: true,
            path: '/app/admin/experts/:expertId/bookings',
            component: lazy(() => import('views/bookings/AdminCalendarView')),
          },
          {
            exact: true,
            path: '/app/admin/experts/:expertId',
            component: lazy(() => import('views/experts/ExpertDetailsView')),
          },
          {
            exact: true,
            path: '/app/admin/experts',
            component: lazy(() => import('views/experts/ExpertListView')),
          },
          {
            exact: true,
            path: '/app/admin/clients/:clientId/edit',
            component: lazy(() => import('views/clients/ClientEditView')),
          },
          {
            exact: true,
            path: '/app/admin/clients/:clientId',
            component: lazy(() => import('views/clients/ClientDetailsView')),
          },
          {
            exact: true,
            path: '/app/admin/clients',
            component: lazy(() => import('views/clients/ClientListView')),
          },
          {
            exact: true,
            path: '/app/admin/adminUsers',
            component: lazy(() => import('views/adminUsers/AdminUsersListView')),
          },
          {
            exact: true,
            path: '/app/admin/bookings',
            component: lazy(() => import('views/bookings/AdminBookingsListView')),
          },
          {
            exact: true,
            path: '/app/admin/discounts',
            component: lazy(() => import('views/discounts/AdminDiscountsListView')),
          },
          {
            exact: true,
            path: '/app/admin/hotelRequest',
            component: lazy(() => import('views/hotelRequest/HotelRequestsView')),
          },
          {
            exact: true,
            path: '/app/admin/voucher_discounts',
            component: lazy(
              () => import('views/discounts/voucherDiscounts/AdminVoucherDiscountsListView'),
            ),
          },
          {
            exact: true,
            path: '/app/admin/voucher_discounts/new',
            component: lazy(
              () => import('views/discounts/voucherDiscounts/VoucherDiscountAddView'),
            ),
          },
          {
            exact: true,
            path: '/app/admin/voucher_discounts/:discountId',
            component: lazy(
              () => import('views/discounts/voucherDiscounts/VoucherDiscountDetailsView'),
            ),
          },
          {
            exact: true,
            path: '/app/admin/voucher_discounts/:discountId/edit',
            component: lazy(
              () => import('views/discounts/voucherDiscounts/VoucherDiscountEditView'),
            ),
          },
          {
            exact: true,
            path: '/app/admin/calendar',
            component: lazy(() => import('views/calendar/AdminCalendar')),
          },
        ],
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('views/account/AccountView')),
      },
      {
        exact: true,
        path: '/app/account/expert/edit',
        guard: ExpertGuard,
        component: lazy(() => import('views/account/ExpertAccountEditView')),
      },
      {
        path: '/app/expert',
        guard: ExpertGuard,
        routes: [
          {
            exact: true,
            path: '/app/expert/earnings',
            component: lazy(() => import('views/earnings/ExpertEarningsView')),
          },
          {
            exact: true,
            path: '/app/expert/bookings/list',
            component: lazy(() => import('views/bookings/ExpertBookingListView')),
          },
          {
            exact: true,
            path: '/app/expert/bookings/:bookingId',
            component: lazy(() => import('views/bookings/BookingDetailsView')),
          },
          {
            exact: true,
            path: '/app/expert/bookings',
            component: lazy(() => import('views/bookings/CalendarView')),
          },
          {
            exact: true,
            path: '/app/expert/reviews',
            component: lazy(() => import('views/reviews/ReviewsListView')),
          },
          {
            exact: true,
            path: '/app/expert/clients/:clientId',
            component: lazy(() => import('views/clients/ExpertClientDetailsView')),
          },
        ],
      },
      { component: () => <Redirect to="/404" /> },
    ],
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        guard: GuestGuard,
        component: HomeView,
      },
      {
        exact: true,
        path: '/account_blocked',
        component: lazy(() => import('views/auth/AccountBlockedView')),
      },
      { component: (): JSX.Element => <Redirect to="/404" /> },
    ],
  },
];

export default routes;
