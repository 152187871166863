export default {
  AdminAdapter: Symbol('AdminAdapter'),
  ExpertAdapter: Symbol('ExpertAdapter'),
  AvailabilityAdapter: Symbol('AvailabilityAdapter'),
  BookingAdapter: Symbol('BookingAdapter'),
  ClientAdapter: Symbol('ClientAdapter'),
  AuthAdapter: Symbol('AuthAdapter'),
  TreatmentTypeAdapter: Symbol('TreatmentTypeAdapter'),
  EarningsAdapter: Symbol('EarningsAdapter'),
  DiscountAdapter: Symbol('DiscountAdapter'),
  PayoutAdapter: Symbol('PayoutAdapter'),
  PayoutsAdapter: Symbol('PayoutsAdapter'),
  ReviewAdapter: Symbol('ReviewAdapter'),
  VoucherAdapter: Symbol('VoucherAdapter'),
};
