import { gql, useQuery } from '@apollo/client';
import currency from 'currency.js';
import IDiscountAdapter, {
  PaginatedAdminDiscount,
  AdminDiscount,
} from 'domain/adapters/pageDataAdapters/IDiscountAdapter';
import Discount, {
  WithDiscountTreatmentType,
  DiscountSort,
  WithDiscountExperts,
} from 'domain/entities/discount';
import { PaginatedResult, Pagination } from 'domain/types/Pagination';
import { QueryResult } from 'domain/types/QueryResult';
import { Sort } from 'domain/types/Sort';
import { Entity, ID } from 'domain/types/common';
import { useMemo } from 'react';
import client from 'utils/apollo';

const GQL_ACTIONS = {
  CREATE_DISCOUNT: gql`
    mutation CreateDiscount($code: String!, $discountData: DiscountInput!) {
      createDiscount(code: $code, discountData: $discountData)
    }
  `,
  UPDATE_DISCOUNT: gql`
    mutation UpdateDiscount($id: ID!, $discountData: DiscountInput!) {
      updateDiscount(discountId: $id, discountData: $discountData)
    }
  `,
  GET_DISCOUNT_BY_ID: gql`
    query GetDiscountById($id: ID!) {
      discount(discountId: $id) {
        id
        activeFrom
        activeTo
        code
        enabled
        experts{
          id
          name
          profilePictureUrl
          treatmentTypes
          customId
        }
        fixedValue
        maxUsages
        minimalTotalPrice
        percentValue
        reusable
        treatmentTimeFrom
        treatmentTimeTo
        treatmentType {
          id
          name {
            lang
            value
          }
        }
        usages
        internalNotes
      }
    }
  `,
  GET_ADMIN_DISCOUNTS: gql`
    query GetAdminDiscounts($pagination: Pagination!, $codeFilter: String, $sort: Sort, $discountIds: [ID]) {
      discounts(pagination: $pagination, codeFilter: $codeFilter, sort: $sort, discountIds: $discountIds) {
        nodes {
          activeFrom
          activeTo
          code
          createdAt
          enabled
          fixedValue
          id
          maxUsages
          minimalTotalPrice
          percentValue
          reusable
          treatmentTimeFrom
          treatmentTimeTo
          treatmentType {
            id
            name {
              lang
              value
            }
          }
          usages
        }
        count
      }
    }
  `,
};

const DiscountGQLAdapter: IDiscountAdapter = {
  async createDiscount(input: Discount): Promise<ID> {
    const res = await client.mutate({
      mutation: GQL_ACTIONS.CREATE_DISCOUNT,
      variables: {
        code: input.code,
        discountData: {
          activeFrom: input.activeFrom || null,
          activeTo: input.activeTo || null,
          enabled: input.enabled,
          experts: input.experts || null,
          fixedValue: input.fixedValue.toString(),
          maxUsages: input.maxUsages || null,
          minimalTotalPrice: input.minimalTotalPrice?.toString(),
          percentValue: input.percentValue,
          reusable: input.reusable,
          treatmentTimeFrom: input.treatmentTimeFrom || null,
          treatmentTimeTo: input.treatmentTimeTo || null,
          treatmentType: input.treatmentType || null,
          internalNotes: input.internalNotes || null,
        },
      },
      refetchQueries: ['GetAdminDiscounts'],
    });
    if (!res.data?.createDiscount) {
      throw new Error('Error while creating discount');
    }
    return res.data.createDiscount;
  },
  async updateDiscount(discountId: ID, input: Discount): Promise<void> {
    const res = await client.mutate({
      mutation: GQL_ACTIONS.UPDATE_DISCOUNT,
      variables: {
        id: discountId,
        discountData: {
          activeFrom: input.activeFrom || null,
          activeTo: input.activeTo || null,
          enabled: input.enabled,
          experts: input.experts || null,
          fixedValue: input.fixedValue.toString(),
          maxUsages: input.maxUsages || null,
          minimalTotalPrice: input.minimalTotalPrice?.toString(),
          percentValue: input.percentValue,
          reusable: input.reusable,
          treatmentTimeFrom: input.treatmentTimeFrom || null,
          treatmentTimeTo: input.treatmentTimeTo || null,
          treatmentType: input.treatmentType || null,
          internalNotes: input.internalNotes || null,
        },
      },
      refetchQueries: ['GetDiscountById', 'GetAdminDiscounts'],
    });
    if (res.errors) {
      throw new Error('Error while updating discount');
    }
  },
  useDiscount(discountId: ID): QueryResult<{
    discount: Entity<WithDiscountTreatmentType<WithDiscountExperts<Discount>>> | null;
  }> {
    const query = useQuery(GQL_ACTIONS.GET_DISCOUNT_BY_ID, {
      variables: {
        id: discountId,
      },
    });

    return useMemo(() => {
      const discount = query.data?.discount || {};
      return {
        refetch: query.refetch,
        loading: query.loading,
        error: !!query.error,
        ...(query.data?.discount
          ? {
              data: {
                discount: {
                  id: discount.id,
                  code: discount.code,
                  minimalTotalPrice: discount.minimalTotalPrice
                    ? currency(discount.minimalTotalPrice)
                    : undefined,
                  fixedValue: currency(discount.fixedValue),
                  treatmentType: discount.treatmentType,
                  activeFrom: discount.activeFrom,
                  activeTo: discount.activeTo,
                  enabled: discount.enabled,
                  experts: discount.experts,
                  maxUsages: discount.maxUsages,
                  percentValue: discount.percentValue,
                  reusable: discount.reusable,
                  treatmentTimeFrom: discount.treatmentTimeFrom,
                  treatmentTimeTo: discount.treatmentTimeTo,
                  createdAt: new Date(),
                  usages: discount.usages,
                  internalNotes: discount.internalNotes,
                },
              },
            }
          : {}),
      };
    }, [query.data, query.error, query.loading, query.refetch]);
  },
  useAdminDiscounts(
    codeFilter: String,
    pagination: Pagination,
    sort: Sort<DiscountSort> | null,
    selectedDiscountIds?: ID[],
  ): QueryResult<PaginatedAdminDiscount> {
    const query = useQuery<{ discounts: PaginatedResult<{ nodes: AdminDiscount[] }> }>(
      GQL_ACTIONS.GET_ADMIN_DISCOUNTS,
      {
        variables: {
          pagination,
          codeFilter: codeFilter?.length >= 2 ? codeFilter : null,
          ...(sort
            ? {
                sort: {
                  field: sort.field,
                  order: sort.order,
                },
              }
            : {}),
          discountIds: selectedDiscountIds?.length ? selectedDiscountIds : undefined,
        },
      },
    );
    return useMemo(
      () => ({
        refetch: query.refetch,
        loading: query.loading,
        error: !!query.error,
        ...(query.data?.discounts
          ? {
              data: {
                count: query.data.discounts?.count || 0,
                discounts: query.data.discounts?.nodes || [],
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },
};

export default DiscountGQLAdapter;
