import PropTypes from 'prop-types';
import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

interface IGuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<IGuestGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { state } = useLocation<{ referrer: string }>();

  if (isAuthenticated) {
    return <Redirect to={state?.referrer || '/app/account'} />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = { children: PropTypes.node };

export default GuestGuard;
