import IImageValidationService from 'domain/services/IImageValidationService';
import { injectable } from 'inversify-props';

const MAXIMAL_WIDTH = 1600;
const MAXIMAL_HEIGHT = 1600;

const MINIMAL_HEIGHT = 800;
const MINIMAL_WIDTH = 800;

@injectable()
export default class ImageValidationService implements IImageValidationService {
  async validateImages(media: File[]): Promise<string[]> {
    return (
      await Promise.all<string>(
        media.map((medium) => {
          if (medium.type.match('video.*')) {
            return Promise.resolve<string>('');
          }
          return new Promise((resolve) => {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
              const image = new Image();
              image.onload = () => {
                if (
                  image.width > MAXIMAL_WIDTH ||
                  image.height > MAXIMAL_HEIGHT ||
                  image.height < MINIMAL_HEIGHT ||
                  image.width < MINIMAL_WIDTH
                ) {
                  resolve('invalid_file_dimentions');
                  return;
                }
                resolve(undefined);
              };
              image.src = e.target.result as string;
            };
            fileReader.readAsDataURL(medium);
          });
        }),
      )
    ).filter((str) => str);
  }
}
