import type { TFunction } from 'i18next';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { TranslatableString, TranslatableStringLanguage } from 'domain/types/common';
import useTranslatableQueryFunction from 'hooks/useTranslatableQueryFunction';
import i18n from './i18n';

type TranslationFunction = TFunction;

export const useTranslate = () => {
  const findTranslatable = useTranslatableQueryFunction();
  return (translatable: TranslatableString) =>
    (translatable.find(findTranslatable) || translatable[0])?.value;
};

export const useOptionalTranslate = () => {
  const findTranslatable = useTranslatableQueryFunction();
  return (translatable: TranslatableStringLanguage[]): string =>
    translatable.find(findTranslatable)?.value || '';
};

export const useContextTranslation = (context: string): TranslationFunction => {
  const { t } = useTranslation();
  return useCallback(
    (key: string, interpolationMap: any) => t(`${context}.${key}`, interpolationMap),
    [t, context],
  );
};

export default {
  i18n,
  useContextTranslation,
};
