import { ApolloClient, InMemoryCache } from '@apollo/client';
import { API_URL } from 'app_constants';

const client = new ApolloClient({
  uri: `${API_URL}/graphql`,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  headers: {
    'Apollo-Require-Preflight': 'true',
  },
  defaultOptions: {
    watchQuery: { fetchPolicy: 'cache-and-network' },
    query: { fetchPolicy: 'network-only' },
  },
});

export default client;
