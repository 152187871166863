import Page from 'components/Page';
import React from 'react';
import type { FC } from 'react';
import Hero from './Hero';

const HomeView: FC = () => {
  return (
    <Page title="Soulhouse CMS">
      <Hero />
    </Page>
  );
};

export default HomeView;
