import { gql, useQuery } from '@apollo/client';
import IReviewAdapter, { Review } from 'domain/adapters/pageDataAdapters/IReviewAdapter';
import { PaginatedResult, Pagination } from 'domain/types/Pagination';
import { QueryResult } from 'domain/types/QueryResult';
import { ID } from 'domain/types/common';
import { useMemo } from 'react';

const GQL_ACTIONS = {
  GET_EXPERT_REVIEWS: gql`
    query GetExpertReviews($expertId: ID!, $pagination: Pagination!) {
      expertReviews(expertId: $expertId,pagination: $pagination) {
        nodes {
          booking
          clientData {
            name
            surname
          }
          createdAt
          rating
          text
          treatmentType {
            description {
              lang
              value
            }
            id
            media {
              type
              uri
            }
            name {
              lang
              value
            }
          }
        }
        count
      }
    }
  `,
};

const ReviewGQLAdapter: IReviewAdapter = {
  useExpertReviews(
    expertId: ID,
    pagination: Pagination,
  ): QueryResult<PaginatedResult<{ reviews: Review[] }> | null> {
    const query = useQuery<{ expertReviews: PaginatedResult<{ nodes: Review[] }> | null }>(
      GQL_ACTIONS.GET_EXPERT_REVIEWS,
      {
        variables: {
          expertId,
          pagination,
        },
      },
    );
    return useMemo(
      () => ({
        refetch: query.refetch,
        loading: query.loading,
        error: !!query.error,
        ...(query.data?.expertReviews
          ? {
              data: {
                count: query.data.expertReviews?.count || 0,
                reviews: query.data.expertReviews?.nodes || [],
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },
};

export default ReviewGQLAdapter;
