import { NotEmptyArray, TranslatableString } from 'domain/types/common';

export default function mapTranslatableStr(
  enhancedTranslatable: NotEmptyArray<any>,
): TranslatableString {
  return enhancedTranslatable.map((t) => ({
    value: t.value,
    lang: t.lang,
  })) as TranslatableString;
}
