import MomentUtils from '@date-io/moment';
import { StylesProvider, ThemeProvider, jssPreset } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import CookiesNotification from 'components/CookiesNotification';
import GlobalStyles from 'components/GlobalStyles';
import GoogleAnalytics from 'components/GoogleAnalytics';
import ScrollReset from 'components/ScrollReset';
import SettingsNotification from 'components/SettingsNotification';
import { AuthProvider } from 'contexts/JWTAuthContext';
import { createBrowserHistory } from 'history';
import useSettings from 'hooks/useSettings';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import type { FC } from 'react';
import { Router } from 'react-router-dom';
import routes, { renderRoutes } from 'routes';
import { createTheme } from 'theme';
import 'translation/i18n';
import { authStore } from 'contexts/AuthAtoms';
import injectables from 'domain/injectables';
import ILocaleService from 'domain/services/ILocaleService';
import { useInject } from 'inversify-hooks';
import { Provider } from 'jotai';
import 'assets/global.scss';
import 'assets/variables.scss';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App: FC = () => {
  const { settings } = useSettings();
  const [localeService] = useInject<ILocaleService>(injectables.LocaleService);

  React.useEffect(() => {
    localeService.initialize().catch(console.error);
  }, [localeService]);

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  return (
    <Provider store={authStore}>
      <ThemeProvider theme={theme}>
        <StylesProvider jss={jss}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <SnackbarProvider dense maxSnack={3}>
              <Router history={history}>
                <AuthProvider>
                  <GlobalStyles />
                  <ScrollReset />
                  <GoogleAnalytics />
                  <CookiesNotification />
                  <SettingsNotification />
                  {renderRoutes(routes)}
                </AuthProvider>
              </Router>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
