import { gql, useQuery } from '@apollo/client';
import currency from 'currency.js';
import IEarningsAdapter from 'domain/adapters/pageDataAdapters/IEarningsAdapter';
import { QueryResult } from 'domain/types/QueryResult';
import { ID } from 'domain/types/common';

const EXPERT_EARNINGS_QUERY = gql`
  query ExpertEarnings($expertId: ID!) {
    expertEarnings(expertId: $expertId) {
      amount
      completedBookings
    }
  }
`;

const EarningsGQLAdapter: IEarningsAdapter = {
  useExpertEarnings(id: ID): QueryResult<{ amount: currency; completedBookings: number }> {
    const query = useQuery<{ expertEarnings: { amount: string; completedBookings: number } }>(
      EXPERT_EARNINGS_QUERY,
      {
        variables: {
          expertId: id,
        },
        fetchPolicy: 'no-cache',
      },
    );

    return {
      loading: query.loading,
      error: Boolean(query.error),
      data: query.data?.expertEarnings
        ? {
            amount: currency(query.data.expertEarnings.amount),
            completedBookings: query.data.expertEarnings.completedBookings,
          }
        : null,
      refetch: query.refetch,
    };
  },
};

export default EarningsGQLAdapter;
