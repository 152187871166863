import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import type { FC } from 'react';

interface ILogoProps {
  [key: string]: any;
}

const useStyles = makeStyles(() => ({
  logo: {
    objectFit: 'contain',
    maxWidth: '100%',
  },
}));

const Logo: FC<ILogoProps> = ({ className, props }) => {
  const classes = useStyles();
  return (
    <img className={clsx(className, classes.logo)} alt="Logo" src="/static/logo.png" {...props} />
  );
};

export default Logo;
